import { Sepolia, Ethereum, LineaSepolia } from "@thirdweb-dev/chains";

/* -- set app title --*/
const AppTitle = 'Admin Cheeba NFT Collections';

/* -- set app mode -- */
// const AppMode = [''];
// const AppMode = ['development'];
const AppMode = ['production'];

/* -- set API URLs --*/
const testing = 'https://dserver.cheebapet.io';
const production = 'https://server.cheebapet.io';
const development = 'https://dserver.cheebapet.io';

let SocketUrl;
let env = AppMode[0] || 'development', network, networkId, message, explorer, opensea, stripeKey, baseURL, URI, REACT_APP_TEMPLATE_CLIENT_ID, assets;
switch (AppMode[0]) {
  case 'development':
    network = LineaSepolia;
    networkId = 59141;
    message = 'Please switch your network to Linea Sepolia testnet';
    SocketUrl = development;
    explorer = 'https://rpc.sepolia.linea.build';
    REACT_APP_TEMPLATE_CLIENT_ID = 'd4e861e5e630a3d6aeb6b5197f45ac5c';
    break;
  case 'production':
    network = Ethereum;
    networkId = 1;
    SocketUrl = production;
    message = 'Please switch your network to Ethereum Mainnet';
    explorer = 'https://etherscan.io';
    REACT_APP_TEMPLATE_CLIENT_ID = 'd4e861e5e630a3d6aeb6b5197f45ac5c';
    break;
  case 'testing':
    network = Sepolia;
    networkId = 4;
    SocketUrl = testing;
    message = 'Please switch your network to Rinkeby testnet';
    explorer = 'https://rinkeby.etherscan.io';
    REACT_APP_TEMPLATE_CLIENT_ID = 'd4e861e5e630a3d6aeb6b5197f45ac5c';
    break;
  default:
    network = LineaSepolia;
    networkId = 59141;
    SocketUrl = 'http://localhost:4000';
    message = 'Please switch your network to Linea Sepolia testnet';
    explorer = 'https://rpc.sepolia.linea.build';
    REACT_APP_TEMPLATE_CLIENT_ID = 'd4e861e5e630a3d6aeb6b5197f45ac5c';
  // network = Ethereum;
  // networkId = 1;
  // SocketUrl = 'http://localhost:4000';
  // message = 'Please switch your network to Ethereum Mainnet';
  // explorer = 'https://etherscan.io';
  // REACT_APP_TEMPLATE_CLIENT_ID = 'd4e861e5e630a3d6aeb6b5197f45ac5c';
}

let ApiUrl = `${SocketUrl}/api`;
export { AppTitle, ApiUrl, SocketUrl, networkId, message, explorer, env, network, REACT_APP_TEMPLATE_CLIENT_ID };
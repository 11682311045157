import Box from '@mui/material/Box';
import EventBus from 'eventing-bus';
import Step from '@mui/material/Step';
import ReactTable from 'react-table-6';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState, Fragment } from 'react';
import {
  createCollection, setLoader, getSingleCollection, deployCollection, currentCollectionStep, setAddMetadatPath,
  updateCollection, collectionStatus, editMetadataObject
} from '../../store/actions/Auth';

import './index.css';
import 'react-table-6/react-table.css';

const steps = ['Create New Collection', 'Add NFT Metadata', 'Deploy Collection'];

const CreateNewCollection = () => {
  const dispatch = useDispatch();
  const { newCollectionData, singleCollection, currentStep, collectionOptStatus } = useSelector(state => state.Auth);

  const [collectionData, setCollectionData] = useState([]);
  const [activeStep, setActiveStep] = useState(currentStep || 0);
  const [symbol, setSymbol] = useState(newCollectionData['symbol']);
  const [tokenName, setTokenName] = useState(newCollectionData['tokenName']);
  const [createButtonText, setCreateButtonText] = useState('Create Collection');
  const [collectionName, setCollectionName] = useState(newCollectionData['collectionName']);
  const [isCollectionCreated, setIsCollectionCreated] = useState(newCollectionData['isCollectionCreated']);

  useEffect(() => {
    if (currentStep < 1) dispatch(currentCollectionStep(0));
    collectionStatus(null)
  }, []);

  useEffect(() => {
    setActiveStep(currentStep);
    if (currentStep == 1) dispatch(getSingleCollection(newCollectionData['collectionName']));
  }, [currentStep]);

  useEffect(() => {
    let newData = [];

    if (singleCollection['numberOfToken']) {
      const fetchTokenData = async () => {
        for (let i = 1; i < singleCollection['numberOfToken']; i++) {
          try {
            const tokenURL = `${singleCollection['baseURI']}${i}.json`;
            const response = await fetch(tokenURL);
            const tokenData = await response.json();
            tokenData['id'] = i;
            newData.push(tokenData);
          } catch (error) {
            console.error('Error fetching token data:', error);
          }
        }
        setCollectionData(newData);
      };

      fetchTokenData();
    }
  }, [singleCollection]);


  useEffect(() => {
    if (collectionOptStatus === false) {
      dispatch(setLoader({ status: false }));
    } else if (collectionOptStatus === true) {
      setIsCollectionCreated(true);
      setCreateButtonText('Edit Collection');
      dispatch(setLoader({ status: false }));
    }
  }, [collectionOptStatus])

  const validateForm = () => {
    if (collectionName.trim() === '' || tokenName.trim() === '' || symbol.trim() === '') {
      EventBus.publish('error', 'CollectionName, TokenName and Symbol are required fields');
      return false;
    } else if (collectionName !== collectionName.toLowerCase() || /\s/.test(collectionName)) {
      EventBus.publish('info', 'Collection name must be in lowercase and should not contain spaces.');
      return false;
    }
    return true;
  };

  const handleCreateOrUpdateCollection = () => {
    if (!validateForm()) return;

    if (isCollectionCreated) dispatch(updateCollection({ collectionName, tokenName, symbol }));
    else {
      dispatch(setLoader({ message: 'Creating NFT Collection...', status: true }));
      dispatch(createCollection({ collectionName, tokenName, symbol }));
    }
  };


  const handleNext = () => {
    if (currentStep == 0 && !isCollectionCreated) return EventBus.publish('error', 'Please create the collection first.');
    dispatch(currentCollectionStep(currentStep + 1));
  };

  const handleBack = () => dispatch(currentCollectionStep(currentStep - 1));

  const handleDeployCollection = () => {
    const collectionId = singleCollection["_id"];
    dispatch(deployCollection({ collectionId }));
    dispatch(setLoader({ message: 'Deploying Collection Contract...', status: true }));
  };

  const columns = [
    {
      id: 'Id',
      Header: 'Id',
      accessor: (collection) => collection['id']
    },
    {
      id: 'Name',
      Header: 'Name',
      accessor: (collection) => collection['name']
    },
    {
      id: 'Description',
      Header: 'Description',
      accessor: (collection) => collection['description']
    },
    {
      id: 'FrontImage',
      Header: 'Front Image',
      accessor: collection => collection ? (
        <>
          <a href={collection['frontImage']} target='_blank'>[Link]</a>
        </>
      ) : "-",
    },
    {
      id: 'BackImage',
      Header: 'Back Image',
      accessor: collection => collection ? (
        <>
          <a href={collection['backImage']} target='_blank'>[Link]</a>
        </>
      ) : "-",
    },
  ];

  const renderTable = () => (
    <Fragment>
      <div className='main-container-head mb-3'>
        <ReactTable
          minRows={5}
          className="table"
          columns={columns}
          filterable={true}
          data={collectionData}
          resolveData={collectionData => collectionData.map(row => row)}
        />
      </div>
    </Fragment>
  );

  console.log("*** singleCollection", singleCollection);

  return (
    <div className='content'>
      <div className="main-container collection-container-main">
        <div className='collections-container'>
          <div className='collections-header collection-header'>
            {activeStep === 0
              ? < Link className="back-btn" to="/home/collection"><img src={require('../../../src/static/images/back-arrow.png')} alt="modal-logo" /></Link>
              : < button className="back-btn" onClick={() => handleBack()}><img src={require('../../../src/static/images/back-arrow.png')} alt="modal-logo" /></button>
            }
            <h1 className="collections-title">Create New Collection</h1>
          </div>
          <div>
            <Box sx={{ width: '100%' }}>
              <Stepper activeStep={activeStep} className='create-banner-step'>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {activeStep === steps.length - 1 ? (
                <Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    <div className='col-12 collection-detail-page'>
                      <div className='row'>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                          <div className='group-form'>
                            <label>Collection Name:</label>
                            <span>{collectionName}</span>
                          </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                          <div className='group-form'>
                            <label>Token Name:</label>
                            <span>{tokenName}</span>
                          </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                          <div className='group-form'>
                            <label>Symbol:</label>
                            <span>{symbol}</span>
                          </div>
                        </div>
                        <div className='col-12'>
                          {renderTable()}
                        </div>
                      </div>
                    </div>
                  </Typography>

                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={() => handleDeployCollection()}>Deploy</Button>
                  </Box>
                </Fragment>
              ) : (
                <Fragment>
                  <div className='row'>
                    <div className={activeStep <= 0 ? 'col-lg-12' : 'col-lg-12'}>
                      <Typography className='heading-step' sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
                    </div>
                  </div>
                  <form className="step-form">
                    {activeStep === 0 && (
                      <div className='col-12'>
                        <div className='row'>
                          <div className='col-12'>
                            <div className='group-form'>
                              <label htmlFor="collectionName">Collection Name:</label>
                              <input
                                id="collectionName"
                                type="text"
                                value={collectionName}
                                onChange={(e) => setCollectionName(e.target.value)}
                                className='text-field'
                                placeholder="Enter Collection Name"
                                disabled={isCollectionCreated}
                              />
                            </div>
                          </div>
                          <div className='col-lg-6 col-md-12'>
                            <div className='group-form'>
                              <label htmlFor="tokenName">Token Name:</label>
                              <input
                                id="tokenName"
                                type="text"
                                value={tokenName}
                                onChange={(e) => setTokenName(e.target.value)}
                                className='text-field'
                                placeholder="Enter Token Name"
                              />
                            </div>
                          </div>
                          <div className='col-lg-6 col-md-12'>
                            <div className='group-form'>
                              <label htmlFor="symbol">Symbol:</label>
                              <input
                                id="symbol"
                                type="text"
                                value={symbol}
                                onChange={(e) => setSymbol(e.target.value)}
                                className='text-field'
                                placeholder="Enter Symbol"
                              />
                            </div>
                          </div>
                          <div className='col-12'>
                            <Button onClick={handleCreateOrUpdateCollection}>
                              {createButtonText}
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                    {activeStep === 1 && (
                      <div className='content'>
                        <div className="main-container">
                          <div className='collections-container'>
                            <div className='collections-header'>
                              <h2 className="collections-title">Add NFT Metadata</h2>
                              <div className='df'>
                                <Link className="btn btn-primary" onClick={() => { dispatch(setAddMetadatPath(`createnewcollection`)); dispatch(editMetadataObject({})) }} to={`/home/createnewcollection/add-new-metadata/${collectionName}/${singleCollection['numberOfToken']}`}>Add Metadata</Link>
                              </div>
                            </div>
                            <div>
                              {renderTable()}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </form>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={() => handleBack()}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleNext} disabled={!isCollectionCreated}>
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                  </Box>
                </Fragment>
              )}
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewCollection;

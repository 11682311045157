import EventBus from "eventing-bus";
import Error from '@material-ui/icons/Error';
import { createBrowserHistory } from "history";
import PrivateRoute from "../store/PrivateRoute";
import React, { useEffect, Suspense } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import Login from './Login';
import Admin from "./Home/Admin";

import '../static/css/style.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import "react-toastify/dist/ReactToastify.css";

const hist = createBrowserHistory();

const App = () => {
  useEffect(() => {
    EventBus.on('info', (e) => toast.info(() => <div> <Error /> {e}</div>));
    EventBus.on('error', (e) => toast.error(() => <div> <Error /> {e}</div>));
    EventBus.on('success', (e) => toast.success(() => <div> <CheckCircle /> {e}</div>));
  }, []);

  return (
    <div>
      <Suspense>
        <ToastContainer closeOnClick position="bottom-left" />
        <Router history={hist}>
          <Switch>
            <Route path="/login" component={props => <Login {...props} />} />
            <PrivateRoute path="/home" component={props => <Admin {...props} />} />
            <Redirect from="/" to="/login" />
          </Switch>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;

import EventBus from 'eventing-bus';
import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { validate } from 'wallet-address-validator';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import './index.css';
import { web3 } from '../../store/web3';
import { useDispatch, useSelector } from 'react-redux';
import { TokenABI, TokenAddress } from '../../store/contract/index.js';
import { setLoader } from '../../store/actions/Auth.js';
import tradingCards from '../../store/tradingCards.json';
import { Web3Button } from '@thirdweb-dev/react';

const Airdrop = ({ }) => {
  const [address, setAddress] = useState([]);
  const [tokenIds, setTokenIds] = useState([]);
  const [quantity, setQuantity] = useState([]);

  const dispatch = useDispatch();

  // const TokenABI = useSelector(state => console.log("TokenABI", state.Auth.TokenABI));
  // const TokenAddress = useSelector(state => state.Auth.TokenAddress);

  const submitAirdrop = async (contract) => {
    // let Token = new web3.eth.Contract(TokenABI, TokenAddress);

    try {
      if (tokenIds.length === 0) {
        EventBus.publish('error', `Please select the Token IDs`);
        return;
      };

      if (quantity.length === 0) {
        EventBus.publish('error', `Please enter the quantity of tokens`);
        return;
      };

      if (quantity.length !== tokenIds.length) {
        EventBus.publish('error', `Token Id's are not matched with quantities`);
        return;
      }

      if (address.length === 0) {
        EventBus.publish('error', `Please enter the address`);
        return;
      };

      for (let i = 0; i < address.length; i++) {
        if (!validate(address[i], 'ETH')) {
          EventBus.publish('error', `This ${address[i]} is not valid`);
          return;
        };
      }

      // const from = (await web3.currentProvider.enable())[0];
      dispatch(setLoader({ message: 'Airdrop in Progress...', status: true }));

      const { receipt } = await contract.call("airdrop", [address, tokenIds, quantity]);
      if (receipt) EventBus.publish('success', `NFT Airdrop successfully`);
    } catch (e) {
      console.log('********Error = ', e);
      dispatch(setLoader({ message: 'Airdrop not Completed...', status: false }));
      EventBus.publish('error', `Unable to Airdrop NFT`);
    };
  };

  return (
    <div className='content'>
      <div className="main-container mint-new"> 
        <div className='mint-new-NFT collections-container'>
          <div className='edit-add row'>
            <div className='collections-header col-lg-8 offset-lg-2 col-md-12'>
              <h1 className="collections-title">Airdrop NFT's</h1>
            </div>
            <div className="edit-add-body col-lg-8 offset-lg-2 col-md-12">
              <ValidatorForm onSubmit={submitAirdrop}>
                <div className="row">
                  <div className='edit-add-field text-left col-12'>
                    <Select
                      multiple
                      value={tokenIds}
                      variant="outlined"
                      id="demo-multiple-checkbox"
                      onChange={(e) => setTokenIds(e.target.value)}
                      className='form-input-field w-100'
                      renderValue={(selected) => selected.join(', ')}
                    >
                      <MenuItem disabled value="">
                        <em>Select Token IDs</em>
                      </MenuItem>
                      {tradingCards.map(item => (
                        <MenuItem key={item['tokenId']} class="d-flex" value={item['tokenId']}>
                          <Checkbox checked={tokenIds.indexOf(item['tokenId']) > -1} />
                          <ListItemText primary={item['name']} />
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className='edit-add-field col-12'>
                    <TextValidator
                      fullWidth
                      type='text'
                      name='quantity'
                      value={quantity}
                      variant="outlined"
                      id='standard-full-width'
                      className='form-input-field'
                      label={<label>No. of Tokens <span>*</span></label>}
                      onChange={(e) => setQuantity(e.target.value.split(','))}
                      helperText="Please separate Quantity with comma(,) and don't put space in between."
                    />
                  </div>
                  <div className='edit-add-field col-12'>
                    <TextValidator
                      fullWidth
                      type='textarea'
                      name='address'
                      value={address.join('\n')}
                      variant="outlined"
                      id='standard-full-width'
                      className='form-input-field'
                      label={<label>Addresses <span>*</span></label>}
                      onChange={(e) => {
                        const addresses = e.target.value.split('\n')
                        setAddress(addresses);
                      }}
                      multiline
                      rows={3}
                      helperText="Please separate addresses by giving line space"
                    />
                  </div>
                  <div className='edit-add-buttons col-12'>
                    {/* <Button className="submit-btn col-4" type='submit' onSubmit={submitAirdrop}>Submit</Button> */}
                    <Web3Button contractAddress={TokenAddress} action={(contract) => submitAirdrop(contract)}>Submit</Web3Button>
                  </div>
                </div>
              </ValidatorForm>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Airdrop;

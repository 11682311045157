import ReactTable from 'react-table-6';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import React, { useEffect, useState, Fragment } from 'react';
import { createCollection, getCollections, updateCollection, deleteCollection, deployCollection, setLoader } from '../../store/actions/Auth';

import './index.css';
import 'react-table-6/react-table.css';

const Collections = () => {
  const dispatch = useDispatch();
  const [symbol, setSymbol] = useState('');
  const [tokenName, setTokenName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [collectionName, setCollectionName] = useState('');
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState(null);

  const { collections } = useSelector(state => state.Auth);

  useEffect(() => {
    dispatch(getCollections());
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setEditModalOpen(false);
    setSelectedCollection(null);
  };

  const handleDelete = (collectionId) => {
    dispatch(setLoader({ message: 'Deleting Collection...', status: true }));
    dispatch(deleteCollection(collectionId));
  };

  const handleDeployCollection = (collectionId) => {
    dispatch(deployCollection({ collectionId }));
  };

  const handleEdit = (collection) => {
    setSelectedCollection(collection['collectionName']);
    setTokenName(collection['tokenName']);
    setSymbol(collection['symbol']);
    setEditModalOpen(true);
  };

  const handleSubmitEdit = (e) => {
    e.preventDefault();
    const data = { tokenName, symbol };
    dispatch(updateCollection({ selectedCollection, data }))
    closeModal();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (collectionName.trim() === '' || collectionName !== collectionName.toLowerCase() || /\s/.test(collectionName)) {
      setErrorMessage('Collection name must be in lowercase and should not contain spaces.');
      return;
    }

    dispatch(createCollection({ collectionName, tokenName, symbol }));

    setCollectionName('');
    setTokenName('');
    setSymbol('');
    setErrorMessage('');
    closeModal();
  };

  const columns = [
    {
      id: 'CollectionName',
      Header: 'Collection Name',
      accessor: (collection) => collection['collectionName']
    },
    {
      id: 'TokenName',
      Header: 'Token Name',
      accessor: (collection) => collection['tokenName']
    },
    {
      id: 'Symbol',
      Header: 'Symbol',
      accessor: (collection) => collection['symbol']
    },
    {
      id: 'collectionAddress',
      Header: 'Collection Address',
      accessor: (collection) => collection['deployedCollectionAddress']
    },
    {
      id: 'Actions',
      Header: 'Actions',
      accessor: collection => collection ? (
        <>
          <Link variant="outlined" color="primary" className='edit-button' to={`/home/collection/editcollection/${collection['collectionName']}`}>Add/Edit Metadata</Link>
          {!collection['isDeployed'] &&
            <button onClick={() => handleDeployCollection(collection['_id'])} color="primary" className='success-button'>Deploy</button>
          }
          <button onClick={() => handleDelete(collection['collectionName'])} variant="outlined" color="primary" className='delete-button'>Delete</button>
        </>
      ) : "-",
    },
  ];

  return (
    <div className='content'>
      <div className="main-container">
        <div className='collections-container'>
          <div className='collections-header'>
            <h1 className="collections-title">Collections</h1>
            <Link className="btn btn-primary" to={`/home/CreateNewCollection`}><i className='icon'><img src={require('../../../src/static/images/plus-btn.png')} alt="modal-logo" /></i> New Collection</Link>
          </div>
          <div>
            <Fragment>
              <div className='main-container-head mb-3'>
                <ReactTable
                  minRows={20}
                  className="table"
                  columns={columns}
                  filterable={true}
                  data={collections}
                  resolveData={collections => collections.map(row => row)}
                />
              </div>
            </Fragment>
          </div>
        </div>
      </div>
      {/* Modal */}
      <Modal isOpen={showModal} toggle={closeModal} className="main-modal allowlist-modal create-collection-modal" style={{ margin: "auto" }}>
        <ModalHeader toggle={closeModal}>
          <div className="modal-logo">
            <img src={require('../../../src/static/images/logo.png')} alt="modal-logo" />
            <h2 className="text-white">Create New Collection</h2>
          </div>
        </ModalHeader>
        <ModalBody className="modal-body">
          {errorMessage && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className='row'>
              <div className='col-12'>
                <div className='group-form'>
                  <label htmlFor="collectionName" style={{ color: 'white' }}>Collection Name:</label>
                  <input
                    id="collectionName"
                    type="text"
                    value={collectionName}
                    onChange={(e) => setCollectionName(e.target.value)}
                    className='text-field'
                    placeholder="Enter Collection Name"
                  />
                </div>
              </div>
              <div className='col-lg-6 col-md-12'>
                <div className='group-form'>
                  <label htmlFor="tokenName" style={{ color: 'white' }}>Token Name:</label>
                  <input
                    id="tokenName"
                    type="text"
                    value={tokenName}
                    onChange={(e) => setTokenName(e.target.value)}
                    className='text-field'
                    placeholder="Enter Token Name"
                  />
                </div>
              </div>
              <div className='col-lg-6 col-md-12'>
                <div className='group-form'>
                  <label htmlFor="symbol" style={{ color: 'white' }}>Symbol:</label>
                  <input
                    id="symbol"
                    type="text"
                    value={symbol}
                    onChange={(e) => setSymbol(e.target.value)}
                    className='text-field'
                    placeholder="Enter Symbol"
                  />
                </div>
              </div>
              <div className='col-12'>
                <div className="group-form text-center">
                  <button type="submit" className="btn btn-primary">Submit</button>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>

      {/* Edit Modal */}
      <Modal isOpen={editModalOpen} toggle={closeModal} className="main-modal allowlist-modal create-collection-modal" style={{ margin: "auto" }}>
        <ModalHeader toggle={closeModal}>
          <div className="modal-logo">
            <img src={require('../../../src/static/images/logo.png')} alt="modal-logo" />
            <h2 className="text-white">Edit Collection</h2>
          </div>
        </ModalHeader>
        <ModalBody className="modal-body">
          <form onSubmit={handleSubmitEdit}>
            <div className='row'>
              <div className='col-lg-6 col-md-12'>
                <div className='group-form'>
                  <label htmlFor="editTokenName" style={{ color: 'white' }}>Token Name:</label>
                  <input
                    id="editTokenName"
                    type="text"
                    value={tokenName}
                    onChange={(e) => setTokenName(e.target.value)}
                    className='text-field'
                    placeholder="Enter Token Name"
                  />
                </div>
              </div>
              <div className='col-lg-6 col-md-12'>
                <div className='group-form'>
                  <label htmlFor="editSymbol" style={{ color: 'white' }}>Symbol:</label>
                  <input
                    id="editSymbol"
                    type="text"
                    value={symbol}
                    onChange={(e) => setSymbol(e.target.value)}
                    className='text-field'
                    placeholder="Enter Symbol"
                  />
                </div>
              </div>
              <div className='col-12'>
                <div className="group-form text-center">
                  <button type="submit" className="btn btn-primary">Submit</button>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div >
  );
};

export default Collections;

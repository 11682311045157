/*========== PAGE LOADER ACTIONS ============= */

import { type } from "@testing-library/user-event/dist/cjs/utility/type.js";

export const setLoader = (data) => ({
  type: 'SET_LOADER',
  payload: data,
});

/*========== LOGIN ACTIONS ============= */

export const login = (data) => ({
  type: 'LOGIN',
  payload: data,
});

export const setLogin = (data) => ({
  type: 'SET_LOGIN',
  payload: data,
});


export const isloginDisabled = () => ({
  type: 'IS_LOGIN_DISABLED',
});

export const logout = () => ({
  type: 'LOGOUT'
});

export const setAddress = (data) => ({
  type: 'SET_ADDRESS',
  payload: data
});

/*========== SMART CONTRACT ACTIONS ============= */

export const getSmartContract = (data) => ({
  type: 'GET_SMART_CONTRACT',
  payload: data
});

export const setSmartContract = (data) => ({
  type: 'SET_SMART_CONTRACT',
  payload: data
});

/*========== ALLOW LIST ACTIONS ============= */

export const getAllowListed = () => ({
  type: 'GET_ALLOW_LISTED',
});

export const setAllowListed = (data) => ({
  type: 'SET_ALLOW_LISTED',
  payload: data
});

export const updateAllowListed = (data) => ({
  type: 'UPDATE_ALLOW_LISTED',
  payload: data
});


/* ---------------- ALLOW LIST SIGNUPS ACTIONS ---------------------- */

export const getAllowListedSignups = () => ({
  type: 'GET_ALLOW_LISTED_SIGNUPS',
});

export const setAllowListedSignups = (data) => ({
  type: 'SET_ALLOW_LISTED_SIGNUPS',
  payload: data
});

export const addNewAllowlistSignups = (data) => ({
  type: 'ADD_NEW_ALLOW_LISTED_SIGNUPS',
  payload: data
});

export const updateAllowListedSignups = (data) => ({
  type: 'UPDATE_ALLOW_LISTED_SIGNUPS',
  payload: data
});

export const deleteAllowListedSignups = (data) => ({
  type: 'DELETE_ALLOW_LISTED_SIGNUPS',
  payload: data
});

export const toggleAddAllowistModal = (data) => ({
  type: 'TOGGLE_ADD_ALLOWLIST_MODAL',
  payload: data
});

export const toggleEditAllowistModal = (data) => ({
  type: 'TOGGLE_EDIT_ALLOWLIST_MODAL',
  payload: data
});

export const toggleDeleteAllowistModal = (data) => ({
  type: 'TOGGLE_DELETE_ALLOWLIST_MODAL',
  payload: data
});

/* ---------------- Collection ---------------------- */
export const createCollection = (data) => ({
  type: 'CREATE_COLLECTION',
  payload: data
});

export const collectionStatus = (data) => ({
  type: 'CREATE_COLLECTION_STATUS',
  payload: data
})

export const getCollections = () => ({
  type: 'GET_COLLECTIONS',
});

export const setCollections = (data) => ({
  type: 'SET_COLLECTIONS',
  payload: data
});

export const getSingleCollection = (data) => ({
  type: 'GET_SINGLE_COLLECTION',
  payload: data
});

export const setSingleCollection = (data) => ({
  type: 'SET_SINGLE_COLLECTION',
  payload: data
});

export const updateCollection = (data) => ({
  type: 'UPDATE_COLLECTION',
  payload: data
});

export const deleteCollection = (data) => ({
  type: 'DELETE_COLLECTION',
  payload: data
});

export const deployCollection = (data) => ({
  type: 'DEPLOY_COLLECTION',
  payload: data
});

export const collectionDeploymentStatus = (data) => ({
  type: 'COLLECTION_DEPLOYMENT_STATUS',
  payload: data
});

/* ---------------- Upload Metadata Data ---------------------- */
export const uploadNftData = (data) => ({
  type: 'UPLOAD_NFT_DATA',
  payload: data
});

export const uploadNftSuccess = (data) => ({
  type: 'UPLOAD_NFT_SUCCESS',
  payload: data
});

export const setAddMetadatPath = (data) => ({
  type: 'SET_ADD_METADATA_PATH',
  payload: data
});

/* ---------------- Collection Steps ---------------------- */

export const currentCollectionStep = (data) => ({
  type: 'SET_COLLECTION_CURRENT_STEP',
  payload: data
});

export const setNewCollectionData = (data) => ({
  type: 'SET_COLLECTION_DATA',
  payload: data,
});

/* ---------------- Deploy CrowdSale ---------------------- */
export const deployCrowdsale = (data) => ({
  type: 'DEPLOY_CROWDSALE',
  payload: data,
});

export const getCrowdSaleAddress = (data) => ({
  type: 'GET_CROWDSALE_ADDRESS',
  payload: data,
});

export const setCrowdSaleAddress = (data) => ({
  type: 'SET_CROWDSALE_ADDRESS',
  payload: data,
});

export const crowdsaleAddressSuccess = (data) => ({
  type: 'CROWDSALE_ADDRESS_SUCCESS',
  payload: data,
});

export const editMetadataObject = (data) => ({
  type: 'EDIT_METADATA_OBJECT',
  payload: data,
})


/* ---------------- Rewards ---------------------- */

export const getAllRewards = (data) => ({
  type: 'GET_ALL_REWARDS',
  payload: data,
});

export const setAllRewards = (data) => ({
  type: 'SET_ALL_REWARDS',
  payload: data,
});

export const toggleShippedModal = (data) => ({
  type: 'TOGGLE_SHIPPED_MODAL',
  payload: data,
});

export const updateShippedStatus = (data) => ({
  type: 'UPDATE_SHIPPED_STATUS',
  payload: data,
});

/* ---------------- Deploy Stages ---------------------- */
export const getCrowdSaleStages = (data) => ({
  type: 'GET_CROWDSALE_STAGES',
  payload: data,
});

export const saleStageAddedSuccess = (data) => ({
  type: 'SALE_STAGE_SUCCESS',
  payload: data,
})

export const setCrowdSaleStages = (data) => ({
  type: 'SET_CROWDSALE_STAGES',
  payload: data,
});

/* ---------------- Crowdsale Whitelisting ---------------------- */
export const whitelistingAddresses = (data) => ({
  type: 'WHITELISTING_ADDRESSES',
  payload: data,
});

export const whitelistingSuccess = (data) => ({
  type: 'WHITELISTING_SUCCESS',
  payload: data,
});

export const getWhitelistedAddress = (data) => ({
  type: 'GET_WHITELISTED_ADDRESSES',
  payload: data,
});

export const setWhitelistedAddress = (data) => ({
  type: 'SET_WHITELISTED_ADDRESSES',
  payload: data,
});
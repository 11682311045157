import React from 'react';
import { render } from 'react-dom';
import { logger } from 'redux-logger';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware } from 'redux';
import { ThirdwebProvider, metamaskWallet, coinbaseWallet, walletConnect, embeddedWallet, trustWallet, en } from '@thirdweb-dev/react';

import './index.css';
import App from './app';
import rootSaga from './store/sagas';
import rootReducer from './store/reducers';
import reportWebVitals from './reportWebVitals';
import { REACT_APP_TEMPLATE_CLIENT_ID, network } from './store/config';

import './static/css/style.css';
import "./static/css/nucleo-icons.css";
import "./static/scss/black-dashboard-react.css";

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware, logger));
sagaMiddleware.run(rootSaga);

const AppWithWalletConnect = () => (
  <ThirdwebProvider
    locale={en()}
    activeChain={network}
    clientId={REACT_APP_TEMPLATE_CLIENT_ID}
    supportedWallets={[
      trustWallet(),
      walletConnect(),
      coinbaseWallet(),
      metamaskWallet({ recommended: true }),
      embeddedWallet({ auth: { options: ["email", "google"] } }),
    ]}
  >
    <App />
  </ThirdwebProvider>
);

render(
  <Provider store={store}>
    <AppWithWalletConnect />
  </Provider>,
  document.getElementById('root')
);
if (module.hot) { module.hot.accept(App); }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
import { Container } from "reactstrap";

const Footer = () => {
  return (
    <footer className="footer">
      <Container fluid>
        <div className="copyright">
          © {new Date().getFullYear()} made with{" "}
          <i className="tim-icons icon-heart-2" /> by{" "}
          <a
            href="https://softtik.com/"
            rel="noopener noreferrer"
            target="_blank"
            className="font-weight-bolder"
          >
            Softtik Technologies
          </a>{" "}
          for a better trading experience.
        </div>
      </Container>
    </footer>
  );
};

export default Footer;

import axios from 'axios';
import { ApiUrl } from '../config';
import EventBus from 'eventing-bus';
import { all, takeEvery, call, put } from 'redux-saga/effects';

import {
  setLogin, setLoader, setAllowListed, setAllowListedSignups, toggleAddAllowistModal, toggleEditAllowistModal,
  toggleDeleteAllowistModal, getCollections, setSmartContract, setCollections, setSingleCollection, getSingleCollection,
  setNewCollectionData, setCrowdSaleAddress, collectionStatus, crowdsaleAddressSuccess, setAllRewards, toggleShippedModal,
  currentCollectionStep, uploadNftSuccess, setCrowdSaleStages, setWhitelistedAddress, whitelistingSuccess
} from '../actions/Auth';

/*========== LOGIN =============*/
function* login({ payload }) {
  try {
    const { error, response } = yield call(postCall, { path: '/users/loginWithAdminWallet', payload });
    if (error) {
      const errorMessage = error['response']?.['data']?.['message'] || 'An error occurred during signin.';
      EventBus.publish('error', errorMessage);
    } else if (response) {
      yield put(setLogin(response['data']['body']));
      window.location.replace('/home');
    }
  } catch (error) {
    console.error('Unexpected error during signin:', error);
  }
}


/*========== SMART CONTRACT FUNCTIONS =============*/
function* getSmartContract() {
  const { error, response } = yield call(getCall, '/nft/smartContractsAdmin');
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) yield put(setSmartContract(response['data']['body']));
};

/*========== ALLOW LIST FUNCTIONS =============*/
function* getAllowListed() {
  const { error, response } = yield call(getCall, '/users/getAllowListed');
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) yield put(setAllowListed(response['data']['body']));
};

function* updateAllowListed({ payload }) {
  if (!payload) return;
  const { error, response } = yield call(postCall, { path: `/users/allowListed`, payload });
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) {
    yield put({ type: 'GET_SMART_CONTRACT' });
    yield put({ type: 'GET_ALLOW_LISTED' });
  }
};

/*========== ALLOW LIST SIGNUPS FUNCTIONS =============*/

function* getAllowListedSignups() {
  const { error, response } = yield call(getCall, '/users/getAllowListedSignups');
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) {
    yield put(setAllowListedSignups(response['data']['body']));
  }
};

function* addNewAllowlistSignups({ payload }) {
  const { error, response } = yield call(postCall, { path: `/users/allowList`, payload });
  if (error) {
    yield put(toggleAddAllowistModal(false));
    yield put(setLoader({ message: 'Allowlist Record Updated...', status: false }));
    EventBus.publish('error', error['response']['data']['message']);
  }
  else if (response) {
    yield put({ type: 'GET_ALLOW_LISTED_SIGNUPS' });
    yield put(toggleAddAllowistModal(false));
    yield put(setLoader({ message: 'Allowlist Record Updated...', status: false }));
    EventBus.publish('success', response['data']['message']);
  }
};

function* updateAllowListedSignups({ payload }) {
  if (!payload) return;
  const { error, response } = yield call(putCall, { path: `/users/editAllowListedSignups`, payload });
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) {
    yield put({ type: 'GET_ALLOW_LISTED_SIGNUPS' });
    yield put(toggleEditAllowistModal(false));
    EventBus.publish('success', response['data']['message']);
  }
  yield put(setLoader({ message: 'Allowlist Record Updated...', status: false }));
}

function* deleteAllowListedSignups({ payload }) {
  if (!payload) return;
  const { error, response } = yield call(putCall, { path: `/users/deleteAllowListedSignups`, payload });
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) {
    yield put(toggleDeleteAllowistModal(false));
    yield put({ type: 'GET_ALLOW_LISTED_SIGNUPS' });
    EventBus.publish('success', response['data']['message']);
  }
  yield put(setLoader({ message: 'Allowlist Record Deleted...', status: false }));
}

/*========== COLLECTION FUNCTIONS =============*/
function* createCollection({ payload }) {
  const { collectionName, tokenName, symbol } = payload;
  const { error, response } = yield call(postCall, { path: `/collections/createCollection`, payload: { collectionName, tokenName, symbol } });

  if (error) {
    yield put(collectionStatus(false));
    EventBus.publish('error', error['response']['data']['message']);
  } else if (response) {
    yield put(getCollections());
    yield put(collectionStatus(true));
    yield put(setNewCollectionData({ collectionName, tokenName, symbol, isCollectionCreated: true }));
    yield put(currentCollectionStep(1));
    EventBus.publish('success', response['data']['message']);
  }
}

function* getAllCollections() {
  const { error, response } = yield call(getCall, '/collections/allCollections');
  if (error) {
    EventBus.publish('info', error['response']['data']['message']);
    yield put(setCollections([]));
  } else if (response) {
    yield put(setCollections(response['data']['body']));
    yield put(setLoader({ message: 'Get CrowdSale Contracts', status: false }));
  }
};

function* getSingleNFTCollection({ payload }) {
  const { error, response } = yield call(getCall, `/collections/getCollection/${payload}`);
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) {
    yield put(setSingleCollection(response['data']['body']));
  }
};

function* updateCollection({ payload }) {
  const { collectionName, tokenName, symbol } = payload;
  const { error, response } = yield call(putCall, { path: `/collections/updateCollection/${collectionName}`, payload: { tokenName, symbol } });

  if (error) {
    yield put(collectionStatus(false));
    EventBus.publish('error', error['response']['data']['message']);
  } else if (response) {
    yield put(getCollections());
    yield put(collectionStatus(true));
    yield put(setNewCollectionData({ collectionName, tokenName, symbol, isCollectionCreated: true }));
    EventBus.publish('success', response['data']['message']);
  }
}


function* deleteCollection({ payload }) {
  if (!payload) return;
  const { error, response } = yield call(deleteCall, `/collections/deleteCollection/${payload}`);
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) {
    yield put(getCollections());
    EventBus.publish('success', response['data']['message']);
  }
  yield put(setLoader({ message: 'Deleting Collection...', status: false }));
}

function* deployCollection({ payload }) {
  if (!payload) return;
  const { collectionId } = payload;
  const { error, response } = yield call(postCall, { path: `/collections/deployCollection`, payload: { collectionId } });

  if (error) {
    yield put(setLoader({ status: false }));
    EventBus.publish('error', error['response']['data']['message']);
  }
  else if (response) {
    yield put(getCollections());
    yield put(setNewCollectionData({ collectionName: "", tokenName: "", symbol: "", isCollectionCreated: false }));
    yield put(setLoader({ status: false }));
    EventBus.publish('success', response['data']['message']);
    window.location.replace("/home/collection");
  }
}


/*========== NFT METADATA FUNCTIONS =============*/
function* uploadNftData({ payload }) {
  if (!payload) return;
  const { error, response } = yield call(postCall, { path: `/collections/uploadNftData/${payload['collectionName']}/${payload['tokenId']}`, payload: payload['formdata'] });
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) {
    yield put(getSingleCollection(payload['collectionName']));
    yield put(uploadNftSuccess(true));
    EventBus.publish('success', response['data']['message']);
  }
  yield put(setLoader({ message: 'Uploading NFT Metadata...', status: false }));
};

/*========== CROWDSALE FUNCTIONS =============*/
function* deployCrowdSale({ payload }) {
  if (!payload) return;
  const { error, response } = yield call(postCall, { path: `/collections/deployCrowdSale`, payload });
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) {
    EventBus.publish('success', response['data']['message']);
  }
  yield put(setLoader({ message: 'Deploy Crowdsale Contract...', status: false }));
};

function* getCrowdSaleAddress({ payload }) {
  if (!payload) return;
  const { collectionAddress } = payload;
  const { error, response } = yield call(getCall, `/collections/getCrowdSaleAddress/${collectionAddress}`);
  if (error) {
    yield put(setLoader({ message: 'Get deployed crowdsale address...', status: false }));
    EventBus.publish('error', error['response']['data']['message']);
  } else if (response) {
    yield put(setCrowdSaleAddress(response['data']['body']));
    yield put(crowdsaleAddressSuccess(true));
    EventBus.publish('success', response['data']['message']);
  }
};

function* getCrowdSaleStages({ payload }) {
  if (!payload) return;
  const { collectionId: _id } = payload;
  const { error, response } = yield call(getCall, `/collections/getCrowdSaleStages/${_id}`);
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) {
    yield put(setCrowdSaleStages(response['data']['body']));
    yield put(setLoader({ message: 'Get CrowdSale Stages', status: false }));
    EventBus.publish('success', response['data']['message']);
  }
};

/*========== WHITELISTING FUNCTIONS =============*/
function* whitelistingAddresses({ payload }) {
  if (!payload) return;
  const { error, response } = yield call(postCall, { path: `/collections/addWhitelist`, payload });
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) {
    yield put(whitelistingSuccess(true));
    EventBus.publish('success', response['data']['message']);
  }
  yield put(setLoader({ message: 'Whitelisting Address...', status: false }));
};

function* getWhitelistedAddress({ payload }) {
  if (!payload) return;
  const { collectionId } = payload;
  const { error, response } = yield call(getCall, `/collections/getWhitelistAddresses/${collectionId}`);
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) {
    yield put(setWhitelistedAddress(response['data']['body']));
    yield put(setLoader({ message: 'Get Whitelisted Addresses', status: false }));
    EventBus.publish('success', response['data']['message']);
  }
};

/*========== REWARDS FUNCTIONS =============*/
function* getAllRewards() {
  const { error, response } = yield call(getCall, `/nft/allRewards`);
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) yield put(setAllRewards(response['data']['body']));
};

function* updateShippedStatus({ payload }) {
  if (!payload) return;
  const { error, response } = yield call(postCall, { path: `/nft/shippedStatus`, payload });
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) {
    yield put({ type: 'GET_ALL_REWARDS' });
    yield put(toggleShippedModal(false));
    EventBus.publish('success', response['data']['message']);
  }
  yield put(setLoader({ message: 'Updating Status...', status: false }));
};


function* actionWatcher() {
  yield takeEvery('LOGIN', login);
  yield takeEvery('GET_ALL_REWARDS', getAllRewards);
  yield takeEvery('UPLOAD_NFT_DATA', uploadNftData);
  yield takeEvery('GET_ALLOW_LISTED', getAllowListed);
  yield takeEvery('DEPLOY_CROWDSALE', deployCrowdSale);
  yield takeEvery('GET_COLLECTIONS', getAllCollections);
  yield takeEvery('DEPLOY_COLLECTION', deployCollection);
  yield takeEvery('UPDATE_COLLECTION', updateCollection);
  yield takeEvery('CREATE_COLLECTION', createCollection);
  yield takeEvery('DELETE_COLLECTION', deleteCollection);
  yield takeEvery('GET_SMART_CONTRACT', getSmartContract);
  yield takeEvery('GET_CROWDSALE_STAGES', getCrowdSaleStages);
  yield takeEvery('UPDATE_SHIPPED_STATUS', updateShippedStatus);
  yield takeEvery('WHITELISTING_ADDRESSES', whitelistingAddresses);
  yield takeEvery('GET_WHITELISTED_ADDRESSES', getWhitelistedAddress);

  yield takeEvery('UPDATE_ALLOW_LISTED', updateAllowListed);
  yield takeEvery('GET_CROWDSALE_ADDRESS', getCrowdSaleAddress);
  yield takeEvery('GET_SINGLE_COLLECTION', getSingleNFTCollection);
  yield takeEvery('GET_ALLOW_LISTED_SIGNUPS', getAllowListedSignups);
  yield takeEvery('ADD_NEW_ALLOW_LISTED_SIGNUPS', addNewAllowlistSignups);
  yield takeEvery('UPDATE_ALLOW_LISTED_SIGNUPS', updateAllowListedSignups);
  yield takeEvery('DELETE_ALLOW_LISTED_SIGNUPS', deleteAllowListedSignups);
};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(ApiUrl + path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(ApiUrl + path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall(path) {
  return axios
    .delete(ApiUrl + path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(ApiUrl + path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

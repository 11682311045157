import ReactTable from 'react-table-6';
import { Link } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, Fragment } from 'react';
import AddWhitelist from '../../components/AddWhitelist/index.js';
import { getWhitelistedAddress, whitelistingSuccess, setLoader } from '../../store/actions/Auth.js';


import './index.css';
import 'react-table-6/react-table.css';
import "react-datepicker/dist/react-datepicker.css";

const CrowdsaleWhitelist = () => {
  const dispatch = useDispatch();
  const { collectionId } = useParams();
  const [isDrawer, setIsDrawer] = useState(false);
  const [crowdsaleAddress, setCrowdsaleAddress] = useState('');
  const [whitelistAddress, setWhitelistAddress] = useState({});
  const { whitelistedAddresses, addWhitelistSuccess } = useSelector((state) => state.Auth);
  console.log("*** CrowdsaleWhitelist ~ addWhitelistSuccess:", addWhitelistSuccess);

  useEffect(() => {
    dispatch(setLoader({ message: 'Get Whitelisted Addresses', status: true }));
    dispatch(getWhitelistedAddress({ collectionId }));
  }, []);

  useEffect(() => {
    if (whitelistedAddresses && whitelistedAddresses['whitelistAddress']) {
      setWhitelistAddress(whitelistedAddresses['whitelistAddress']);
    }

    if (whitelistedAddresses && whitelistedAddresses['crowdSaleAddress']) {
      setCrowdsaleAddress(whitelistedAddresses['crowdSaleAddress']);
    }
  }, [whitelistedAddresses])

  useEffect(() => {
    if (addWhitelistSuccess) {
      dispatch(whitelistingSuccess(false));
      dispatch(setLoader({ message: 'Get Whitelisted Addresses', status: true }));
      dispatch(getWhitelistedAddress({ collectionId }));
      toggle();
    }
  }, [addWhitelistSuccess])

  const toggle = () => setIsDrawer(!isDrawer);

  const transformWhitelistData = (whitelistAddress) => {
    return Object.entries(whitelistAddress).map(([key, value]) => ({
      key,
      value
    }));
  };

  const columns = [
    {
      id: 'whitelistAddress',
      Header: 'Whitelist Address',
      accessor: 'key'
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: 'value',
      Cell: row => (row.value ? 'Whitelist' : 'Blacklist')
    },
    // {
    //   id: 'Actions',
    //   Header: 'Actions',
    //   accessor: collectionArray => collectionArray ? (
    //     <>
    //       <Link variant="outlined" color="primary" className='edit-button' to={`/home/crowdsale/crowdsaleStage/${collectionArray['_id']}`}>Add Stages</Link>
    //     </>
    //   ) : "-",
    // },
  ];

  return (
    <div className='content'>
      <div className="main-container">
        <div className='collections-container'>
          <div className='collections-header'>
            <h1 className="collections-title">Crowdsale Whitelist</h1>
            {crowdsaleAddress &&
              <button
                className="btn btn-primary"
                onClick={toggle}>
                <i className='icon'>
                  <img src={require('../../../src/static/images/plus-btn.png')}
                    alt="modal-logo" /
                  >
                </i>
                Add Address to Whitelist
              </button>
            }
          </div>
          <div>
            {Object.keys(whitelistAddress).length > 0 &&
              <Fragment>
                <div className='main-container-head mb-3'>
                  <ReactTable
                    minRows={10}
                    className="table"
                    columns={columns}
                    filterable={true}
                    data={whitelistAddress}
                    resolveData={() => transformWhitelistData(whitelistAddress)}
                  />
                </div>
              </Fragment>
            }
            <Drawer className='crowd-stage-drawer' open={isDrawer} onClose={toggle}>
              <AddWhitelist crowdSaleAddress={crowdsaleAddress} />
            </Drawer>
          </div>
        </div>
      </div>
    </div >
  );
};

export default CrowdsaleWhitelist;

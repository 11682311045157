import EventBus from 'eventing-bus';
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { login } from "../../store/actions/Auth";
import logo from '../../../src/static/images/logo.png';
import { networkId, message } from "../../store/config";
import { ValidatorForm } from 'react-material-ui-form-validator';

import { ConnectWallet, useConnectionStatus, useAddress, useChain, useDisconnect, useSDK } from "@thirdweb-dev/react";

import './index.css';

const Login = () => {
  const sdk = useSDK();
  const chain = useChain();
  const address = useAddress();
  const dispatch = useDispatch();
  const disconnect = useDisconnect();
  const connectionStatus = useConnectionStatus();

  const { auth } = useSelector(state => state['Auth']);

  useEffect(() => {
    if (connectionStatus === "connected" && !auth) handleConnectWallet();
  }, [connectionStatus]);

  const handleConnectWallet = () => {
    if (connectionStatus === "connected") {
      if ((chain?.['chainId']) !== networkId) {
        EventBus.publish('info', message);
        return disconnect();
      }
      setTimeout(() => handleLogin(), 500)
    }
  };

  const handleLogin = async () => {
    try {
      let message = `ADMIN-CHEEBA-NFT wants you to sign in with your wallet`;
      let result = await sdk?.wallet.sign(message);
      if (result) dispatch(login({ publicAddress: address, signature: result, requestDomain: 'adminPanel' }));
    } catch (error) {
      disconnect();
      console.error("Error signing message:", error);
    }
  };

  return (
    <div className="login-page">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 login-area">
          <div className="login-form">
            <p className="login-title">【ＬＯＧＩＮ】</p>
            <hr className='mt-3' />
            <ValidatorForm className="validator-form mt-4">
              {(connectionStatus === 'disconnected' || connectionStatus === 'connected') && (
                <ConnectWallet
                  theme={"dark"}
                  modalSize={"wide"}
                  switchToActiveChain={true}
                  modalTitle={"Admin CheebaPet"}
                />
              )}
            </ValidatorForm>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 login-area">
          <img className="login-page-logo" src={logo} alt='logo' />
        </div>
      </div>
    </div>
  );
};

export default Login;

import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { PropTypes } from "prop-types";
import PerfectScrollbar from "perfect-scrollbar";

const Sidebar = ({ bgColor, routes, rtlActive, logo }) => {
  const role = useSelector(state => state.Auth.role);
  const sidebarRef = useRef(null);

  useEffect(() => {
    let ps;
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebarRef.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }

    return () => {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  }, []);

  const activeRoute = (routeName) => {
    return window.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  const linkOnClick = () => {
    document.documentElement.classList.remove("nav-open");
  };

  return (
    <div className="sidebar" data={bgColor}>
      <div className="sidebar-wrapper" ref={sidebarRef}>
        <div className="logo text-center text-white" style={{ padding: '15px 0px' }}>
          <a href={logo.outterLink} target="_blank">
            <img style={{ width: '80%' }} alt="..." src={require('../../static/images/logo.png')} />
          </a>
        </div>
        <nav>
          <ul>
            {routes.map((prop, key) => (
              <React.Fragment key={key}>
                {!prop.hidden && (
                  <li
                    className={
                      activeRoute(prop.path) +
                      (prop.pro ? "active-pro" : "")
                    }
                  >
                    <NavLink
                      to={prop.layout + prop.path}
                      className="nav-link"
                      activeClassName="active"
                      onClick={linkOnClick}
                    >
                      <i className={prop.icon} />
                      <p>{rtlActive ? prop.rtlName : prop.name}</p>
                    </NavLink>
                  </li>
                )}
              </React.Fragment>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

Sidebar.defaultProps = {
  rtlActive: false,
  bgColor: "primary",
  routes: [{}]
};

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  bgColor: PropTypes.oneOf(["primary", "blue", "green"]),
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    text: PropTypes.node,
    imgSrc: PropTypes.string
  })
};

export default Sidebar;

import ReactTable from 'react-table-6';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, Fragment } from 'react';
import { getCollections, setLoader } from '../../store/actions/Auth.js';


import './index.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-table-6/react-table.css';

const steps = ['Create Collection Address', 'Add Stages'];

const CrowdSale = ({ }) => {
  const dispatch = useDispatch();
  const [collectionArray, setCollectionArray] = useState([]);
  const { collections } = useSelector((state) => state.Auth);

  useEffect(() => {
    dispatch(setLoader({ message: 'Get CrowdSale Contracts', status: true }));
    dispatch(getCollections())
  }, []);

  useEffect(() => {
    if (collections.length > 0) {
      const collectionWithCrowdSale = collections.filter((collection) => {
        if (collection['tokenCrowdSaleAddress']) return collection;
      });

      setCollectionArray(collectionWithCrowdSale);
    }
  }, [collections])

  const columns = [
    {
      id: 'CollectionName',
      Header: 'Collection Name',
      accessor: (collectionArray) => collectionArray['collectionName']
    },
    {
      id: 'collectionAddress',
      Header: 'Collection Address',
      accessor: (collectionArray) => collectionArray['deployedCollectionAddress']
    },
    {
      id: 'crowdsaleAddress',
      Header: 'CrowdSale Address',
      accessor: (collectionArray) => collectionArray['tokenCrowdSaleAddress']
    },
    {
      id: 'Actions',
      Header: 'Actions',
      accessor: collectionArray => collectionArray ? (
        <>
          <Link variant="outlined" color="primary" className='success-button' to={`/home/crowdsale/whitelist/${collectionArray['_id']}`}>Whitelist</Link>
          <Link variant="outlined" color="primary" className='edit-button' to={`/home/crowdsale/crowdsaleStage/${collectionArray['_id']}`}>Add Stages</Link>
        </>
      ) : "-",
    },
  ];

  return (
    <div className='content'>
      <div className="main-container">
        <div className='collections-container'>
          <div className='collections-header'>
            <h1 className="collections-title">Crowdsale Contract</h1>
            <Link className="btn btn-primary" to={`/home/CreateNewCrowdsale`}><i className='icon'><img src={require('../../../src/static/images/plus-btn.png')} alt="modal-logo" /></i> New Crowdsale Contract</Link>
          </div>
          <div>
            <Fragment>
              <div className='main-container-head mb-3'>
                <ReactTable
                  minRows={10}
                  className="table"
                  columns={columns}
                  filterable={true}
                  data={collectionArray}
                  resolveData={collectionArray => collectionArray.map(row => row)}
                />
              </div>
            </Fragment>
          </div>
        </div>
      </div>
    </div >
  );
};

export default CrowdSale;

import axios from 'axios';
import EventBus from 'eventing-bus';
import React, { useEffect, useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import placeHolderImg from '../../static/images/placeholder.svg';
import videoHolderImg from '../../static/images/vide-thumbnail.png';
import { getSingleCollection, uploadNftData, setLoader, uploadNftSuccess } from '../../store/actions/Auth';

import './index.css';

const AddMetadata = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { collectionName, metadataId } = useParams();
  const [preview, setPreview] = useState({
    frontImage: null,
    backImage: null,
    animationVideo: null
  });

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    frontImage: null,
    backImage: null,
    animationVideo: null,
    traits: [{ trait_type: '', value: '' }]
  });

  const { metadataBackPath, metadataObject, nftUploadSuccess } = useSelector(state => state.Auth);

  useEffect(() => { dispatch(getSingleCollection(collectionName)); }, [collectionName]);

  const generateImagePreview = (file) => {
    const objectURL = URL.createObjectURL(file);
    const filePreview = new URL(objectURL)['href'];
    return { filePreview, objectURL };
  };

  useEffect(() => {
    if (formData['frontImage'] && formData['frontImage'] instanceof File) {
      const { filePreview, objectURL } = generateImagePreview(formData['frontImage'])
      setPreview(prevState => ({ ...prevState, frontImage: filePreview }));
      return () => URL.revokeObjectURL(objectURL)
    }
    return setPreview(prevState => ({ ...prevState, frontImage: formData['frontImage'] }));
  }, [formData['frontImage']]);

  useEffect(() => {
    if (formData['backImage'] && formData['backImage'] instanceof File) {
      const { filePreview, objectURL } = generateImagePreview(formData['backImage'])
      setPreview(prevState => ({ ...prevState, backImage: filePreview }));
      return () => URL.revokeObjectURL(objectURL)
    }
    return setPreview(prevState => ({ ...prevState, backImage: formData['backImage'] }));
  }, [formData['backImage']]);

  useEffect(() => {
    if (formData['animationVideo'] && formData['animationVideo'] instanceof File) {
      const { filePreview, objectURL } = generateImagePreview(formData['animationVideo'])
      setPreview(prevState => ({ ...prevState, animationVideo: filePreview }));
      return () => URL.revokeObjectURL(objectURL)
    }
    return setPreview(prevState => ({ ...prevState, animationVideo: formData['animationVideo'] }));
  }, [formData['animationVideo']]);

  useEffect(() => {
    if (nftUploadSuccess) {
      emptyStates();
      dispatch(uploadNftSuccess(false));
      history.push(`/home/${metadataBackPath}`, { shallow: true });
    }
  }, [nftUploadSuccess]);

  useEffect(() => {
    if (Object.keys(metadataObject).length > 0) {
      const transformedAttributes = metadataObject.attributes.map(attribute => ({
        trait_type: attribute.trait_type,
        value: attribute.value
      }));

      setFormData({
        name: metadataObject['name'],
        description: metadataObject['description'],
        frontImage: metadataObject['frontImage'],
        backImage: metadataObject['backImage'],
        animationVideo: metadataObject['animation_url'],
        traits: transformedAttributes
      });
    }
  }, [metadataObject]);

  const emptyStates = () => {
    setFormData({
      name: '',
      description: '',
      frontImage: null,
      backImage: null,
      animationVideo: null,
      traits: [{ trait_type: '', value: '' }]
    });

    setPreview({
      frontImage: null,
      backImage: null,
      animationVideo: null
    });
  }

  const handleTraitChange = (index, key, value) => {
    const updatedTraits = formData?.['traits'].length > 0 && formData.traits.map((trait, i) =>
      i === index ? { ...trait, [key]: value } : trait
    );
    setFormData({ ...formData, traits: updatedTraits });
  };

  const handleAddTrait = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      traits: [...prevFormData['traits'], { trait_type: '', value: '' }]
    }));
  };

  const handleDeleteTrait = (index) => {
    const updatedTraits = formData.traits.filter((_, i) => i !== index);
    setFormData({ ...formData, traits: updatedTraits });
  };

  const handleSubmit = () => {
    const { name, description, frontImage, backImage, animationVideo, traits } = formData;

    if (!frontImage || !backImage || !animationVideo || !name || !description || traits.some(trait => !trait.trait_type || !trait.value)) {
      EventBus.publish('error', 'All fields are required');
      return;
    }

    const attributes = traits.map(trait => ({
      trait_type: trait['trait_type'],
      value: trait['value']
    }));

    const metadata = { name, description, attributes };
    const formdata = new FormData();
    frontImage instanceof File ? formdata.append('frontImage', frontImage) : formdata.append('existingFrontImage', frontImage);
    backImage instanceof File ? formdata.append('backImage', backImage) : formdata.append('existingBackImage', backImage);
    animationVideo instanceof File ? formdata.append('animation_url', animationVideo) : formdata.append('existingAnimationURL', animationVideo);
    formdata.append('metadata', JSON.stringify(metadata));
    const data = {
      collectionName,
      tokenId: Number(metadataId),
      formdata
    };

    if (typeof frontImage === "string") data['existingFrontImage'] = frontImage;
    if (typeof backImage === "string") data['existingBackImage'] = backImage;
    if (typeof animationVideo === "string") data['existingAnimationURL'] = animationVideo


    dispatch(uploadNftData(data));
    dispatch(setLoader({ message: 'Uploading NFT Metadata...', status: true }));
  };

  return (
    <div className='content'>
      <div className="main-container">
        <div className='collections-container'>
          <div className='row'>
            <div className='col-lg-8 offset-lg-2 col-md-12'>
              <div className='collections-header collection-header MetaData-heading'>
                <Link className="back-btn" to={`/home/${metadataBackPath}`} onClick={() => emptyStates()}>
                  <img src={require('../../../src/static/images/back-arrow.png')} alt="modal-logo" />
                </Link>
                <h1 className="collections-title">{formData["name"] ? "Update MetaData" : "Add New Metadata"}</h1>
              </div>
              <div className='mt-4'>

                <div className='row'>
                  <div className='col-lg-4 col-md-12'>
                    <label className="df mb0-5rem">Upload Front Image</label>
                    <div className='group-form image-upload-group'>

                      <label
                        htmlFor="frontFileUpload"
                        className="w100 bsbb bg5 br5px df jcc aic px2rem py1rem bsd bo6 bw2px cp mb0-5rem"
                      >
                        <input
                          onChange={(e) => {
                            setFormData({ ...formData, frontImage: e.target.files[0] })
                          }
                          }
                          className="dn"
                          id="frontFileUpload"
                          type="file"
                          accept="image/*"
                        />
                      </label>
                      <div className="df jcc">
                        <img className="popup-img brw5 tac" src={placeHolderImg} alt="Front Upload" />
                        {formData?.['frontImage'] && (
                          <img className="popup-img show-img brw5 tac" src={preview?.['frontImage']} alt="Front Upload" />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='col-lg-4 col-md-12'>
                    <label className="df mb0-5rem">Upload Back Image</label>
                    <div className='group-form image-upload-group'>

                      <label
                        htmlFor="backFileUpload"
                        className="w100 bsbb bg5 br5px df jcc aic px2rem py1rem bsd bo6 bw2px cp mb0-5rem"
                      >
                        <input
                          onChange={(e) => setFormData({ ...formData, backImage: e.target.files[0] })}
                          className="dn"
                          id="backFileUpload"
                          type="file"
                          accept="image/*"
                        />
                      </label>
                      <div className="df jcc">
                        <img className="popup-img brw5 tac" src={placeHolderImg} alt="Front Upload" />
                        {formData?.['backImage'] && (
                          <img className="popup-img show-img brw5 tac" src={preview?.['backImage']} alt="Back Upload" />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-12'>
                    <label className="df mb0-5rem">Upload Animation Video</label>
                    <div className='group-form image-upload-group video-upload-group'>

                      <label
                        htmlFor="animationFileUpload"
                        className="w100 bsbb bg5 br5px df jcc aic px2rem py1rem bsd bo6 bw2px cp mb0-5rem"
                      >
                        <input
                          onChange={(e) => setFormData({ ...formData, animationVideo: e.target.files[0] })}
                          className="dn"
                          id="animationFileUpload"
                          type="file"
                          accept="video/*"
                        />
                      </label>
                      <div className="df jcc">
                        <img className="popup-img brw5 tac" src={videoHolderImg} alt="Front Upload" />
                        {formData['animationVideo'] && (
                          <video className="popup-img show-img brw5 tac" src={preview?.['animationVideo']} controls />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='col-lg-3 col-md-4'>
                    <div className='group-form id-group'>
                      <label htmlFor="name">Token Id:</label>
                      <input
                        type="text"
                        id="id"
                        disabled='true'
                        className="text-field"
                        value={metadataId}
                      />
                    </div>
                  </div>
                  <div className='col-lg-9 col-md-8'>
                    <div className='group-form id-group'>
                      <label htmlFor="name">Name:</label>
                      <input
                        type="text"
                        id="name"
                        value={formData['name']}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        className="text-field"
                      />
                    </div>
                  </div>
                </div>

                <div className='group-form'>
                  <label htmlFor="description">Description:</label>
                  <textarea
                    id="description"
                    value={formData['description']}
                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                    className="text-field"
                    rows="5"
                  />
                </div>

                <div className='group-form'>
                  {formData?.['traits'] && formData['traits'].length > 0 && formData['traits'].map((trait, index) => (
                    <div className="trait-group" key={index}>
                      <div className='row'>
                        <div className='col-lg-6 col-md-12'>
                          <div className='group-form plus-btn'>
                            <label htmlFor={`traitType${index}`}>Trait Type:</label>
                            <input
                              type="text"
                              id={`traitType${index}`}
                              value={trait['trait_type']}
                              onChange={(e) => handleTraitChange(index, 'trait_type', e.target.value)}
                              className="text-field"
                            />
                            <button type="button" className="plus-btn" onClick={() => handleAddTrait()}>+</button>
                          </div>
                        </div>
                        <div className='col-lg-6 col-md-12'>

                          <div className='group-form delete-remove-btn'>
                            <label htmlFor={`traitValue${index}`}>Trait Value:</label>
                            <input
                              type="text"
                              id={`traitValue${index}`}
                              value={trait['value']}
                              onChange={(e) => handleTraitChange(index, 'value', e.target.value)}
                              className="text-field"
                            />
                            <button type="button" className='remove-btn' onClick={() => handleDeleteTrait(index)}>
                              <svg
                                viewBox="0 0 256 256"
                                className="pen fds h1rem f1 mr0-5rem"
                              >
                                <g>
                                  <g>
                                    <g>
                                      <path
                                        className="pen fa-primary"
                                        d="M94.6,3.6c-5.7,1.5-10.5,5.6-13.3,11.2l-1.7,3.4L79.5,28l-0.2,9.7H47.5c-30.4,0-32,0-33.7,0.9c-3.4,1.8-4.9,6.8-2.7,9.7c2.2,2.8,3.3,3.3,8.8,3.5c4.8,0.2,5.1,0.2,5.4,1.2c0.1,0.6,5.6,41.9,12.3,91.9c6.6,49.9,12.2,91.4,12.4,92c0.1,0.7,1,2.7,2,4.5c2.8,5.4,7.9,9.4,13.6,10.9c2.6,0.7,10.2,0.8,62.6,0.8c65.2,0,62.8,0.1,67.7-2.8c5.6-3.3,9.8-9.4,10.8-15.6c0.2-1.6,5.7-43,12.3-91.8c6.5-48.9,11.9-89.4,12-89.9c0.2-1,0.6-1,5.3-1.2c4.3-0.1,5.4-0.3,6.5-1.2c2.3-1.7,3.3-3.4,3.3-5.7c0-2.7-1.3-4.9-3.6-6.2c-1.8-1-2.3-1-33.8-1h-31.9l-0.2-9.7l-0.1-9.7l-1.7-3.4c-2.8-5.7-7.7-9.7-13.6-11.2C156.9,2.5,98.8,2.5,94.6,3.6z M159.3,18.1c2.8,1.7,3.1,2.9,3.1,11.8v7.9H128H93.7v-7.9c0-8.8,0.3-10.1,3.1-11.8c1.5-0.9,2.7-1,31.3-1C156.6,17.1,157.8,17.1,159.3,18.1z M216.3,55c-0.2,1.7-5.7,42.7-12.1,91.1c-9,67.4-11.9,88.3-12.6,89.4c-0.4,0.8-1.7,2-2.7,2.5c-1.9,1-1.9,1-60.9,1c-53.2,0-59.2-0.1-60.7-0.8c-3.6-1.7-2.6,4.2-15.7-93.9C45.1,95,39.6,54,39.5,53.3l-0.2-1.3H128h88.7L216.3,55z"
                                      />
                                      <path d="M78.2,70c-1.7,1-2.8,2.6-3.3,4.4c-0.1,0.6,1.8,32.8,4.4,71.4c3.9,57.9,4.8,70.5,5.5,72c2.6,5.3,9.9,5.2,12.5-0.1c0.9-1.8,0.8-3-3.8-72.7c-5.2-77.6-4.6-72.6-8.1-74.9C83.3,68.9,80.3,68.8,78.2,70z" />
                                      <path d="M124.7,69.9c-0.9,0.4-2.1,1.5-2.6,2.4c-1,1.6-1,2.4-1,73.1c0,70.9,0,71.6,1,73.1c2.6,4.2,9.2,4.2,11.8,0c1-1.6,1-2.3,1-73.1s0-71.6-1-73.1C132.1,69.4,128,68.3,124.7,69.9z" />
                                      <path d="M170.7,70.2c-3.5,2.3-2.9-2.7-8.1,74.8c-4.7,70-4.8,70.8-3.8,72.7c2.6,5.3,10,5.4,12.5,0.1c0.7-1.4,1.6-13.5,5.3-69.2c2.5-37.1,4.5-69.2,4.6-71.2c0.1-3.1,0-3.9-1-5.3C177.9,69,173.8,68.2,170.7,70.2z" />
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className='col-12'>
                  <div className="group-form text-center">
                    <button type="submit" className="btn btn-primary submit-btn" onClick={() => handleSubmit()}>Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMetadata;

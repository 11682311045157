import moment from 'moment';
import EventBus from 'eventing-bus';
import ReactTable from 'react-table-6';
import Drawer from '@mui/material/Drawer';
import Select from '@mui/material/Select';
import DatePicker from "react-datepicker";
import MenuItem from '@mui/material/MenuItem';
import AddNewCrowdSaleStage from "../AddNewStage";
import { formatEther } from '@ethersproject/units';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import React, { useEffect, useState, Fragment } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Web3Button, parseRevertReason, useContract, useContractRead } from '@thirdweb-dev/react';
import { getCrowdSaleStages, saleStageAddedSuccess, setLoader } from '../../store/actions/Auth.js';

import './index.css';
import 'react-table-6/react-table.css';
import "react-datepicker/dist/react-datepicker.css";

const CrowdSaleStages = () => {
  const dispatch = useDispatch();
  const { collectionId } = useParams();
  const [view, setView] = useState(0);
  console.log("***** CrowdSaleStages ~ view:", view);
  const [isDrawer, setIsDrawer] = useState(false);
  const [stagesArray, setStagesArray] = useState([]);
  const [updateOption, setUpdateOption] = useState('');
  const [isStageUpdate, setIsStageUpdate] = useState(false);
  const [crowdSaleAddress, setCrowdSaleAddress] = useState("");
  const [updateOpeningTime, setUpdateOpeningTime] = useState(new Date());
  const [updateClosingTime, setUpdateClosingTime] = useState(new Date());

  const [updateStageState, setUpdateStageState] = useState({
    stageId: null,
    stageName: '',
    stagePrice: 0,
    // mintingLimit: '',
  });

  const { contract: crowdsaleContract } = useContract(crowdSaleAddress);
  const { data: whilelistStatus } = useContractRead(crowdsaleContract, "whitelistStatus");


  const handleViewChange = (event, newView) => setView(newView);
  const handleOpeningTimeChange = (date) => setUpdateOpeningTime(date);
  const handleClosingTimeChange = (date) => setUpdateClosingTime(date);
  const getUnixTimestamp = (date) => Math.floor(date.getTime() / 1000);
  const { crowdSaleStages, saleStagesSuccess } = useSelector((state) => state.Auth);

  useEffect(() => { if (whilelistStatus !== undefined) setView(whilelistStatus) }, [whilelistStatus])

  useEffect(() => {
    dispatch(setLoader({ message: 'Get CrowdSale Stages', status: true }));
    dispatch(getCrowdSaleStages({ collectionId }));
  }, [collectionId]);

  useEffect(() => {
    if (crowdSaleStages['stages']?.length > 0) setStagesArray(crowdSaleStages['stages']);
    if (crowdSaleStages['crowdsaleAddress']) setCrowdSaleAddress(crowdSaleStages['crowdsaleAddress']);
  }, [crowdSaleStages]);

  useEffect(() => {
    if (saleStagesSuccess) {
      dispatch(saleStageAddedSuccess(false));
      toggle();
      dispatch(setLoader({ message: 'Get CrowdSale Stages', status: true }));
      dispatch(getCrowdSaleStages({ collectionId }));
    }
  }, [saleStagesSuccess]);

  const toggle = () => setIsDrawer(!isDrawer);

  const handleUpdateModal = (stageData) => {
    setIsStageUpdate(true);
    setUpdateStageState({
      stageId: stageData['stageId'],
      stageName: stageData['stageName'],
      stagePrice: stageData['stagePrice'],
      // mintingLimit: stageData['mintingLimit'],
    });
    handleOpeningTimeChange(new Date(Number(stageData['openingTime']) * 1000));
    handleClosingTimeChange(new Date(Number(stageData['closingTime']) * 1000));
  };

  const updateCrowdsaleStage = async (contract) => {
    const { stageId, stageName, stagePrice } = updateStageState;

    if (!stageId) {
      EventBus.publish('error', 'Unable to call smart contract without stageId');
      return;
    }

    return new Promise(async (resolve, reject) => {
      try {
        let contractResult;
        const stageIndex = stageId - 1;
        dispatch(setLoader({ message: 'Update Stage in CrowdSale...', status: true }));


        switch (updateOption) {
          case 'stageName':
            if (!stageName || typeof stageName !== 'string') {
              dispatch(setLoader({ message: 'Update Stage in CrowdSale...', status: true }));
              EventBus.publish('error', 'StageName is not given properly or is not a string');
              return;
            }
            contractResult = await contract.call("changeStageName", [stageIndex, stageName]);
            break;

          case 'stagePrice':
            if (!stagePrice || typeof stagePrice !== 'number') {
              dispatch(setLoader({ message: 'Update Stage in CrowdSale...', status: true }));
              EventBus.publish('error', 'StagePrice is not given properly or is not a number');
              return;
            }
            contractResult = await contract.call("editStagePrice", [stageIndex, stagePrice]);
            break;

          // case 'stageMintingLimits':
          //   if (!mintingLimit || typeof mintingLimit !== 'number') {
          //     EventBus.publish('error', 'Minting Limit is not given properly or is not a number');
          //     return;
          //   }
          //   // contractResult = await contract.call("editStageMintingLimit", [stageIndex, mintingLimit]);
          //   break;

          case 'stageTimings':
            if (!updateOpeningTime || !updateClosingTime) {
              dispatch(setLoader({ message: 'Update Stage in CrowdSale...', status: true }));
              EventBus.publish('error', 'Please give proper opening and closing sale stage time');
              return;
            }
            const newOpeningTime = getUnixTimestamp(updateOpeningTime);
            const newClosingTime = getUnixTimestamp(updateClosingTime);
            contractResult = await contract.call("updateStageTiming", [stageIndex, newOpeningTime, newClosingTime]);
            break;

          default:
            EventBus.publish('error', 'Invalid update option');
            return;
        }

        const { receipt } = contractResult;
        if (receipt) {
          dispatch(getCrowdSaleStages({ collectionId }))
          resolve("Stage Added successfully");
        }

      } catch (error) {
        const reason = parseRevertReason(error);
        reject(reason);
        dispatch(setLoader({ message: 'Update Stage in CrowdSale...', status: false }));
      }
    });
  };

  const toggleWhitelistStatus = async (contract) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { receipt } = await contract.call("toggleWhitelistStatus", []);
        if (receipt) {
          resolve("Stage Added successfully");
        }
      } catch (error) {
        const reason = parseRevertReason(error);
        reject(reason);
      }
    });
  }

  const columns = [
    {
      id: 'stageId',
      Header: 'Id',
      accessor: 'stageId',
    },
    {
      id: 'stageName',
      Header: 'Stage Name',
      accessor: 'stageName',
    },
    {
      id: 'stagePrice',
      Header: 'Stage Price (ETH)',
      accessor: (stagesArray) => {
        const stagePriceInEthers = formatEther(stagesArray?.['stagePrice']);
        return stagePriceInEthers.toString();
      }
    },
    {
      id: 'mintingLimit',
      Header: 'Minting Limit',
      accessor: 'mintingLimit',
    },
    {
      id: 'openingTime',
      Header: 'Opening Time',
      accessor: (stagesArray) => moment(new Date(stagesArray['openingTime'] * 1000)).format('MMMM Do YYYY, h:mm:ss a')
    },
    {
      id: 'closingTime',
      Header: 'Closing Time',
      accessor: (stagesArray) => moment(new Date(stagesArray['closingTime'] * 1000)).format('MMMM Do YYYY, h:mm:ss a')
    },
    {
      id: 'Actions',
      Header: 'Actions',
      accessor: (stageData) => (
        <button
          className="btn btn-primary"
          onClick={() => handleUpdateModal(stageData)}
        >
          Update
        </button>
      ),
    },
  ];

  return (
    <div className='content'>
      <div className="main-container">
        <div className='collections-container'>
          <div className='collections-header'>
            <h1 className="collections-title">Crowdsale Stages</h1>
            {crowdSaleAddress && (
              <button className="btn btn-primary" onClick={toggle}>
                <i className='icon'>
                  <img src={require('../../../src/static/images/plus-btn.png')} alt="modal-logo" />
                </i>
                Add New SaleStage
              </button>
            )}
          </div>
            <div className='toggle-button-group'>
              <h3 style={{ color: '#009600' }} > Whitelisting Status</h3>
              <Web3Button
                contractAddress={crowdSaleAddress}
                action={(contract) => toggleWhitelistStatus(contract)}
                onError={(error) => EventBus.publish("error", error)}
                onSuccess={(success) => EventBus.publish("success", success)}
              >
                {view === 1 ? "Deactivate" : "Activate"}
              </Web3Button>
            </div>
            <Fragment>
              <div className='main-container-head mb-3'>
                <ReactTable
                  minRows={10}
                  className="table"
                  columns={columns}
                  filterable={true}
                  data={stagesArray}
                  resolveData={data => data.map(row => row)}
                />
              </div>
            </Fragment>
            <Drawer className='crowd-stage-drawer' open={isDrawer} onClose={toggle}>
              <AddNewCrowdSaleStage crowdSaleAddress={crowdSaleAddress} componentHeading="Add New SaleStage" />
            </Drawer>
        </div>
      </div>

      {/* Update Sale Stage */}
      <Modal isOpen={isStageUpdate} toggle={() => setIsStageUpdate(false)} className="main-modal allowlist-modal">
        <ModalHeader toggle={() => setIsStageUpdate(false)}>
          <div className="modal-logo">
            <img src={require('../../../src/static/images/logo.png')} alt="modal-logo" />
          </div>
          <div className="text-center">
            <h2>Update Sale Stage</h2>
          </div>
          <div>
            <hr />
          </div>
        </ModalHeader>
        <ModalBody className="modal-body mb-4">
          <div className='select-option'>
            <h4>Please select the option to update</h4>
            <Select
              value={updateOption}
              id="demo-simple-select"
              onChange={(e) => setUpdateOption(e.target.value)}
            >
              <MenuItem value={'stageName'}>Stage Name</MenuItem>
              <MenuItem value={'stagePrice'}>Stage Price</MenuItem>
              <MenuItem value={'stageTimings'}>Stage Timings</MenuItem>
              {/* <MenuItem value={'stageMintingLimits'}>Stage Minting Limits</MenuItem> */}
            </Select>
          </div>

          <ValidatorForm className="validator-form">
            <div className='row'>
              <div className='col-12'>
                {updateOption === 'stageName' && (
                  <div className='group-form'>
                    <label htmlFor="stageName" style={{ color: 'green' }}>Stage Name:</label>
                    <input
                      id="stageName"
                      type="text"
                      value={updateStageState['stageName']}
                      onChange={(e) => setUpdateStageState({ ...updateStageState, stageName: e.target.value })}
                      className='text-field'
                      placeholder="Enter New Stage Name"
                    />
                  </div>
                )}
                {updateOption === 'stagePrice' && (
                  <div className='group-form'>
                    <label htmlFor="stagePrice" style={{ color: 'green' }}>Stage Price:</label>
                    <input
                      id="stagePrice"
                      type="number"
                      value={updateStageState['stagePrice']}
                      onChange={(e) => setUpdateStageState({ ...updateStageState, stagePrice: Number(e.target.value) })}
                      className='text-field'
                      placeholder="Enter New Stage Price"
                    />
                  </div>
                )}
                {/* {updateOption === 'stageMintingLimits' && (
                  <div className='group-form'>
                    <label htmlFor="mintingLimit" style={{ color: 'green' }}>Minting Limit:</label>
                    <input
                      id="mintingLimit"
                      type="text"
                      value={updateStageState.mintingLimit}
                      onChange={(e) => setUpdateStageState({ ...updateStageState, mintingLimit: e.target.value })}
                      className='text-field'
                      placeholder="Enter updated minting limits"
                    />
                  </div>
                )} */}
                {updateOption === 'stageTimings' && (
                  <Fragment>
                    <div className='row'>
                      <div className='group-form col-lg-6 col-md-12'>
                        <label htmlFor="openingTime" style={{ color: 'green' }}>Opening Time:</label>
                        <DatePicker
                          selected={updateOpeningTime}
                          onChange={handleOpeningTimeChange}
                          showTimeSelect
                          dateFormat="Pp"
                        />
                      </div>
                      <div className='group-form col-lg-6 col-md-12'>
                        <label htmlFor="closingTime" style={{ color: 'green' }}>Closing Time:</label>
                        <DatePicker
                          selected={updateClosingTime}
                          onChange={handleClosingTimeChange}
                          showTimeSelect
                          dateFormat="Pp"
                        />
                      </div>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
            <div className='edit-add-buttons col-sm-12 mt-4'>
              <Web3Button contractAddress={crowdSaleAddress}
                action={(contract) => updateCrowdsaleStage(contract)}
                onError={(error) => EventBus.publish("error", error)}
                onSuccess={(success) => { setIsStageUpdate(false); EventBus.publish("success", success) }}
              >
                Submit
              </Web3Button>
            </div>
          </ValidatorForm>
        </ModalBody>
      </Modal>
    </div >
  );
};

export default CrowdSaleStages;

import axios from 'axios';
import EventBus from 'eventing-bus';
import ReactTable from 'react-table-6';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState, Fragment } from 'react';
import { getSingleCollection, setAddMetadatPath, editMetadataObject } from '../../store/actions/Auth';

import './index.css';
import 'react-table-6/react-table.css';

const EditMetadata = () => {
  const dispatch = useDispatch();
  const { collectionName } = useParams();
  const [collectionData, setCollectionData] = useState([]);

  const { singleCollection } = useSelector(state => state.Auth);

  useEffect(() => {
    dispatch(getSingleCollection(collectionName));
  }, []);

  useEffect(() => {
    let newData = [];

    if (singleCollection['numberOfToken']) {
      const fetchTokenData = async () => {
        for (let i = 1; i < singleCollection['numberOfToken']; i++) {
          try {
            const tokenURL = `${singleCollection['baseURI']}${i}.json`;
            const response = await fetch(tokenURL);
            const tokenData = await response.json();
            tokenData['id'] = i;
            newData.push(tokenData);
          } catch (error) {
            console.error('Error fetching token data:', error);
          }
        }
        console.log("*** newData", newData);
        setCollectionData(newData);
      };

      fetchTokenData();
    }
  }, [singleCollection]);

  const setMetadataObject = (collection) => {
    dispatch(editMetadataObject(collection));
  }

  const columns = [
    {
      id: 'MetadataId',
      Header: 'Metadata Id',
      accessor: (collection) => collection['id']
    },
    {
      id: 'Name',
      Header: 'Name',
      accessor: (collection) => collection['name']
    },
    {
      id: 'Description',
      Header: 'Description',
      accessor: (collection) => collection['description']
    },
    {
      id: 'FrontImage',
      Header: 'Front Image',
      accessor: collection => collection ? (
        <>
          <a href={collection['frontImage']} target='_blank'>[Link]</a>
        </>
      ) : "-",
    },
    {
      id: 'BackImage',
      Header: 'Back Image',
      accessor: collection => collection ? (
        <>
          <a href={collection['backImage']} target='_blank'>[Link]</a>
        </>
      ) : "-",
    },
    {
      id: 'Actions',
      Header: 'Actions',
      accessor: collection => collection ? (
        <>
          <Link
            className="btn btn-primary"
            onClick={() => {
              setMetadataObject(collection);
              dispatch(setAddMetadatPath(`collection/editcollection/${collectionName}`))
            }}
            to={`/home/createnewcollection/add-new-metadata/${collectionName}/${collection['id']}`}
          >Edit</Link>
        </>
      ) : '-',
    },
  ];

  return (
    <div className='content'>
      <div className="main-container">
        <div className='collections-container'>
          <div className='collections-header'>
            <h1 className="collections-title">Add NFT Metadata</h1>
            <div className='df'>
              <Link className="btn btn-primary" onClick={() => { dispatch(setAddMetadatPath(`collection/editcollection/${collectionName}`)); dispatch(editMetadataObject({})) }} to={`/home/createnewcollection/add-new-metadata/${collectionName}/${singleCollection['numberOfToken']}`}>Add Metadata</Link>
              <Link className="btn btn-primary ml-2" to="/home/collection">Back</Link>
            </div>
          </div>
          <div>
            <Fragment>
              <div className='main-container-head mb-3'>
                <ReactTable
                  minRows={10}
                  className="table"
                  columns={columns}
                  filterable={true}
                  data={collectionData}
                  resolveData={collectionData => collectionData.map(row => row)}
                />
              </div>
            </Fragment>
          </div>
        </div>
      </div>
    </div >
  );
};

export default EditMetadata;

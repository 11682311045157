import React from 'react';
import { useSelector } from 'react-redux';
import { css } from "@emotion/react";
import BounceLoader from "react-spinners/BounceLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Loader = () => {
  const isLoader = useSelector(state => state.Auth.isLoader);

  return (
    <>
      {isLoader.status &&
        <div className='loader'>
          <div className="loader-area">
            <BounceLoader
              css={override}
              size={50}
              color={'#009600'}
              loading={true}
            />
            <span className="loading-text">
              <p className="mt-4 text-white">{isLoader.message}</p>
            </span>
          </div>
        </div>
      }
    </>
  );
};

export default Loader;

import { setToken } from "../axios";
import { PURGE } from "redux-persist";
var initialState =
{
  auth: localStorage.getItem('auth'),
  publicAddress: localStorage.getItem('publicAddress'),
  isLoader: { message: 'Please Wait...', status: false },
  collections: [],
  singleCollection: {},
  metadataBackPath: 'createnewcollection',

  currentStep: 0,
  collectionOptStatus: null,
  getCrowdSaleSuccess: false,

  allAddresses: [],
  isAllowlistModal: false,

  allAddressesSignups: [],
  isAddAllowlistModal: false,
  isEditAllowlistModal: false,
  isDeleteAllowlistModal: false,

  newCollectionData: {
    collectionName: '',
    tokenName: '',
    symbol: '',
    isCollectionCreated: false,
  },
  metadataObject: {},

  allRewards: [],
  isShippedModal: false,
  nftUploadSuccess: false,

  deployedCrowdSaleAddress: '',

  TokenABI: {}, TokenAddress: '',
  CrowdSaleABI: {}, CrowdSaleAddress: '',

  crowdSaleStages: {}, saleStagesSuccess: false,
  whitelistedAddresses: {}, addWhitelistSuccess: false,
};

const Auth = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE: return initialState;

    /*========== ADMIN REDUCERS ============= */

    case 'SET_LOGIN':
      setToken(payload['token']);
      localStorage.setItem('auth', payload['token']);
      localStorage.setItem('publicAddress', payload['publicAddress']);
      return {
        ...state,
        auth: payload['token'],
        publicAddress: payload['publicAddress']
      };

    case 'IS_LOGIN_DISABLED':
      return {
        ...state,
      };

    case 'LOGOUT':
      localStorage.removeItem('auth');
      localStorage.removeItem('publicAddress');
      return {
        ...state,
        auth: null,
        publicAddress: null
      };

    case 'SET_ADDRESS':
      return {
        ...state,
        publicAddress: payload
      };

    case 'SET_BALANCE':
      return {
        ...state,
        balance: payload
      };

    /*========== LOADER REDUCERS ============= */

    case 'SET_LOADER':
      return {
        ...state,
        isLoader: payload
      };

    /*========== SMART CONTRACT REDUCERS ============= */

    case 'SET_SMART_CONTRACT':
      return {
        ...state,
        CrowdSaleABI: payload['CrowdSaleABI'],
        CrowdSaleAddress: payload['CrowdSaleAddress'],
        TokenABI: payload['TokenABI'],
        TokenAddress: payload['TokenAddress'],
      };

    /*========== ALLOW LIST REDUCERS ============= */

    case 'SET_ALLOW_LISTED':
      return {
        ...state,
        allAddresses: payload,
      };

    /*========== ALLOW LIST SIGNUPS REDUCERS ============= */

    case 'SET_ALLOW_LISTED_SIGNUPS':
      return {
        ...state,
        allAddressesSignups: payload,
      };

    case 'TOGGLE_ADD_ALLOWLIST_MODAL':
      return {
        ...state,
        isAddAllowlistModal: payload,
      };

    case 'TOGGLE_EDIT_ALLOWLIST_MODAL':
      return {
        ...state,
        isEditAllowlistModal: payload,
      };

    case 'TOGGLE_DELETE_ALLOWLIST_MODAL':
      return {
        ...state,
        isDeleteAllowlistModal: payload,
      };

    /*========== COLLECTIONS ============= */

    case 'SET_COLLECTIONS':
      return {
        ...state,
        collections: payload,
      };

    case 'SET_SINGLE_COLLECTION':
      return {
        ...state,
        singleCollection: payload,
      };

    case 'SET_COLLECTION_CURRENT_STEP':
      return {
        ...state,
        currentStep: payload,
      };

    case 'SET_COLLECTION_DATA':
      return {
        ...state,
        newCollectionData: payload,
      };

    case 'SET_CROWDSALE_ADDRESS':
      return {
        ...state,
        deployedCrowdSaleAddress: payload,
      };

    case 'CREATE_COLLECTION_STATUS':
      return {
        ...state,
        collectionOptStatus: payload,
      }

    case 'CROWDSALE_ADDRESS_SUCCESS':
      return {
        ...state,
        getCrowdSaleSuccess: payload,
      }

    case 'SET_ADD_METADATA_PATH':
      return {
        ...state,
        metadataBackPath: payload,
      }

    case 'EDIT_METADATA_OBJECT':
      return {
        ...state,
        metadataObject: payload,
      }

    case 'UPLOAD_NFT_SUCCESS':
      return {
        ...state,
        nftUploadSuccess: payload,
      }

    /*========== REWARDS ============= */

    case 'SET_ALL_REWARDS':
      return {
        ...state,
        allRewards: payload,
      };

    case 'TOGGLE_SHIPPED_MODAL':
      return {
        ...state,
        isShippedModal: payload,
      };

    /*========== CrowdSale Stages ============= */
    case 'SET_CROWDSALE_STAGES':
      return {
        ...state,
        crowdSaleStages: payload,
      };

    case 'SALE_STAGE_SUCCESS':
      return {
        ...state,
        saleStagesSuccess: payload,
      };

    /*========== CROWDSALE WHITELISTING ============= */
    case 'WHITELISTING_SUCCESS':
      return {
        ...state,
        addWhitelistSuccess: payload,
      };

    case 'SET_WHITELISTED_ADDRESSES':
      return {
        ...state,
        whitelistedAddresses: payload,
      };

    default:
      return state;
  }
};

export default Auth;
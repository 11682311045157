import EventBus from 'eventing-bus';
import classNames from "classnames";
import { logout } from "../../store/actions/Auth";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useConnectionStatus, useAddress, useDisconnect } from "@thirdweb-dev/react";

// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal
} from "reactstrap";

import './index.css';
import { setAddress } from '../../store/actions/Auth';
import { web3 } from '../../store/web3';
import { Token } from '../../store/contract/index';

const AdminNavbar = () => {
  const address = useAddress();
  console.log("🚀 ~ AdminNavbar ~ address:", address)
  const dispatch = useDispatch();
  const disconnect = useDisconnect();
  const connectionStatus = useConnectionStatus();
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [modalSearch, setModalSearch] = useState(false);
  const [color, setColor] = useState("navbar-transparent");
  const [role, setRole] = useState(localStorage.getItem('role'));
  const { address: publicAddress } = useSelector(state => state.Auth);

  useEffect(() => {
    if (connectionStatus === "connected" && (address !== publicAddress)) setTimeout(() => disconnectWallet(), 500);
  }, [address]);

  const disconnectWallet = () => {
    dispatch(logout());
    EventBus.publish('info', 'Wallet Disconnected');
    disconnect();
    window['location'].replace("/login");
  };

  useEffect(() => {
    window.addEventListener("resize", updateColor);
    // SET ADDRESS
    const setAddressAsync = async () => {
      let address = (await web3.currentProvider.enable())[0];
      dispatch(setAddress(address));
    };
    setAddressAsync();
    return () => {
      window.removeEventListener("resize", updateColor);
    };
  }, [dispatch]);

  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("navbar-transparent");
    }
  };

  const toggleCollapse = () => {
    if (collapseOpen) {
      setColor("navbar-transparent");
    } else {
      setColor("bg-white");
    }
    setCollapseOpen(!collapseOpen);
  };

  const toggleModalSearch = () => {
    setModalSearch(!modalSearch);
  };

  const handleLogout = () => {
    disconnect();
    dispatch(logout());
    window['location'].replace("/login");
  };

  return (
    <div className="nav-bar">
      <Navbar
        className={classNames("navbar-absolute", color)}
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div
              className={classNames("navbar-toggle d-inline", {
                toggled: collapseOpen
              })}
            >
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleCollapse}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand className="mt-2" href="#" target="_blank">
              {/* <img alt="..." src={require('../../static/img/icon.png')} style={{ width: '20%'}} /> */}
            </NavbarBrand>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  nav
                  onClick={e => e.preventDefault()}
                >
                  <div className="photo">
                    <img alt="..." src={require('../../static/images/icon.png')} />
                  </div>
                  <b className="caret d-none d-lg-block d-xl-block" />
                  <p className="d-lg-none">Logout</p>
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  <DropdownItem divider tag="li" />
                  <NavLink tag="li" onClick={handleLogout}>
                    <DropdownItem className="nav-item">Logout</DropdownItem>
                  </NavLink>
                  <DropdownItem divider tag="li" />
                </DropdownMenu>
              </UncontrolledDropdown>
              <li className="separator d-lg-none" />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <Modal
        modalClassName="modal-search"
        isOpen={modalSearch}
        toggle={() => toggleModalSearch}
      >
        <div className="modal-header">
          <Input id="inlineFormInputGroup" placeholder="SEARCH" type="text" />
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModalSearch}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AdminNavbar;

import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import EventBus from 'eventing-bus';
import { useSelector, useDispatch } from 'react-redux';
import ReactTable from 'react-table-6';
import Button from '@material-ui/core/Button';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { validate } from 'wallet-address-validator';
import './index.css';
import 'react-table-6/react-table.css';
import {
  setLoader,
  getAllowListedSignups,
  addNewAllowlistSignups,
  toggleAddAllowistModal,
  updateAllowListedSignups,
  toggleEditAllowistModal,
  deleteAllowListedSignups,
  toggleDeleteAllowistModal
} from '../../store/actions/Auth';

const AllowlistSignups = () => {
  const [state, setState] = useState({
    email: '',
    address: '',
    firstName: '',
    deleteRecord: { email: '', address: '', firstName: '' }
  });

  const dispatch = useDispatch();

  const allAddressesSignups = useSelector(state => state.Auth.allAddressesSignups);
  const isAddAllowlistModal = useSelector(state => state.Auth.isAddAllowlistModal);
  const isEditAllowlistModal = useSelector(state => state.Auth.isEditAllowlistModal);
  const isDeleteAllowlistModal = useSelector(state => state.Auth.isDeleteAllowlistModal);

  useEffect(() => {
    dispatch(getAllowListedSignups());
  }, [dispatch]);

  useEffect(() => {
    if (!isAddAllowlistModal && !isEditAllowlistModal) setState({ ...state, address: '', email: '', firstName: '' });
    if (!isDeleteAllowlistModal) {
      let { deleteRecord } = state;
      deleteRecord['email'] = '';
      deleteRecord['firstName'] = '';
      deleteRecord['address'] = '';
      setState({ ...state, deleteRecord });
    }
  }, [isAddAllowlistModal, isEditAllowlistModal, isDeleteAllowlistModal]);

  const handleEditChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const addNewAllowlist = async () => {
    try {
      let { address, email, firstName } = state;

      if (!(address || email)) {
        EventBus.publish('error', `Please provide at least one of Address or Email`);
        return;
      }

      if (address && !validate(address, 'ETH')) {
        EventBus.publish('error', `Please provide valid address`);
        return;
      }

      if (email && !validateEmail(email)) {
        EventBus.publish('error', `Please provide valid Email address`);
        return;
      }

      dispatch(setLoader({ message: 'Adding New Allowlist Signup ...', status: true }));
      dispatch(addNewAllowlistSignups({ publicAddress: address, email, firstName }));
    } catch (error) {
      dispatch(toggleAddAllowistModal(false));
      EventBus.publish('error', `Unable to add new allowlist address`);
      dispatch(setLoader({ message: 'Allowlisting not Completed...', status: false }));
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const editAllowlist = async () => {
    try {
      let { address, email, firstName } = state;

      if (!(address || email)) {
        EventBus.publish('error', `Please provide at least one of Address or Email`);
        return;
      }

      if (address && !validate(address, 'ETH')) {
        EventBus.publish('error', `Please provide a valid Ethereum address`);
        return;
      }

      if (email && !validateEmail(email)) {
        EventBus.publish('error', `Please provide a valid email address`);
        return;
      }

      dispatch(setLoader({ message: 'Updating Allowlist Record...', status: true }));
      dispatch(updateAllowListedSignups({ address, email, firstName }));
    } catch (e) {
      dispatch(toggleEditAllowistModal(false));
      EventBus.publish('error', `Unable to Allowlist Address`);
      dispatch(setLoader({ message: 'Allowlisting not Completed...', status: false }));
    }
  };

  const editRow = async (row) => {
    const { publicAddress, createdAt, email, firstName } = row._original;
    setState({ ...state, address: publicAddress, createdAt, email, firstName });
    dispatch(toggleEditAllowistModal(true));
  };

  const deleteRecord = async () => {
    try {
      let { address, email, firstName } = state.deleteRecord;
      if (email === '') {
        EventBus.publish('info', 'Email is not added');
        return;
      }
      if (address === '') {
        EventBus.publish('info', 'Address is not added');
        return;
      }
      if (firstName === '') {
        EventBus.publish('info', 'First name is not added');
        return;
      }
      dispatch(setLoader({ message: 'Deleting Allowlist Record...', status: true }));
      dispatch(deleteAllowListedSignups({ address, email, firstName }));
    } catch (error) {
      dispatch(toggleDeleteAllowistModal(false));
      EventBus.publish('error', `Unable to delete this record`);
      dispatch(setLoader({ message: 'Record not Deleted...', status: false }));
    }
  };

  const deleteRow = async (row) => {
    const { deleteRecord } = state;
    const { publicAddress, email, firstName } = row._original;

    dispatch(toggleDeleteAllowistModal(true));
    deleteRecord['email'] = email;
    deleteRecord['firstName'] = firstName;
    deleteRecord['address'] = publicAddress;
    setState({ ...state, deleteRecord });
  };

  const { address, email, firstName } = state;

  const columns = [
    {
      id: 'returnValues',
      Header: 'Allowlisted Address',
      accessor: (allAddressesSignups) => allAddressesSignups['publicAddress'] ? allAddressesSignups['publicAddress'] : '-',
    },
    {
      id: 'blockNumber',
      Header: 'Date',
      accessor: (allAddressesSignups) => moment(allAddressesSignups['createdAt']).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      id: 'Email',
      Header: 'Email',
      accessor: (allAddressesSignups) => allAddressesSignups['email']
    },
    {
      id: 'First Name',
      Header: 'First Name',
      accessor: (allAddressesSignups) => allAddressesSignups['firstName']
    },
    {
      id: 'edit',
      Header: '',
      Cell: ({ row }) => (
        <>
          <Button onClick={() => editRow(row)} variant="outlined" color="primary">Edit</Button>
          <Button onClick={() => deleteRow(row)} variant="outlined" color="primary" className='delete-button'>Delete</Button>
        </>
      )
    },
  ];

  return (
    <div className='content'>
      <div className="main-container mintedNFT">
        <div className='main-container-head mb-3'>
          <p className="main-container-heading">Allowlist Signup's</p>
          <div className='action-buttons'>
            <button onClick={() => toggleAddAllowistModal(true)} className="add-btn">Add Allowlist Signup</button>
            <CSVLink data={allAddressesSignups} filename={"allowListed.csv"} >
              <button className="add-btn"
              >Export Data</button>
            </CSVLink>
          </div>
        </div>
        <Fragment>
          <div className='main-container-head mb-3'>
            <ReactTable
              minRows={10}
              className="table"
              columns={columns}
              filterable={true}
              data={allAddressesSignups}
              resolveData={allAddressesSignups => allAddressesSignups.map(row => row)}
            />
          </div>
        </Fragment>
      </div>


      {/* ---------------ADD ALLOWLIST MODAL--------------- */}

      <Modal isOpen={isAddAllowlistModal} toggle={() => toggleAddAllowistModal(false)} className="main-modal allowlist-modal" style={{ margin: "auto" }}>
        <ModalHeader toggle={() => toggleAddAllowistModal(false)}>
          <div className="modal-logo">
            <img src={require('../../../src/static/images/logo.png')} alt="modal-logo" />
          </div>
          <div className="text-center"><h2>NEW ALLOWLISTED SIGNUPS</h2></div>
          <div><hr /></div>
        </ModalHeader>
        <ModalBody className="modal-body">
          <ValidatorForm className="validator-form" onSubmit={addNewAllowlist}>
            <div className='row'>
              <div className='col-12 group-form'>
                <span>Address: </span>
                <TextValidator
                  fullWidth
                  multiline
                  rows={1}
                  type='text'
                  name='address'
                  value={address}
                  placeholder="Enter the Addresses"
                  className='text-field'
                  id='standard-full-width'
                  onChange={handleEditChange}
                />
              </div>


              <div className='col-12 group-form'>
                <span>Email: </span>
                <TextValidator
                  fullWidth
                  multiline
                  rows={1}
                  type='email'
                  name='email'
                  value={email}
                  placeholder="Enter Email"
                  className='text-field'
                  id='standard-full-width'
                  onChange={handleEditChange}
                />
              </div>

              <div className='col-12 group-form'>
                <span>First Name: </span>
                <TextValidator
                  fullWidth
                  multiline
                  rows={1}
                  type='text'
                  name='firstName'
                  value={firstName}
                  placeholder="Enter First Name"
                  className='text-field'
                  id='standard-full-width'
                  onChange={handleEditChange}
                />
              </div>



            </div>
            <div className="row">
              <div className="col-12 pt-4 pb-5 d-flex justify-content-center">
                <Button type="submit" variant="contained" className="add-btn">
                  Submit
                </Button>
              </div>
            </div>
          </ValidatorForm>
        </ModalBody>

      </Modal>

      {/* ---------------EDIT ALLOWLIST MODAL--------------- */}

      <Modal isOpen={isEditAllowlistModal} toggle={() => toggleEditAllowistModal(false)} className="main-modal allowlist-modal" style={{ margin: "auto" }}>
        <ModalHeader toggle={() => toggleEditAllowistModal(false)}>
          <div className="modal-logo">
            <img src={require('../../../src/static/images/logo.png')} alt="modal-logo" />
          </div>
          <div className="text-center"><h2>EDIT ALLOWLISTED</h2></div>
          <div><hr /></div>
        </ModalHeader>
        <ModalBody className="modal-body">
          <ValidatorForm className="validator-form" onSubmit={editAllowlist}>
            <div className='row'>
              <div className='col-12 group-form'>
                <span>Address: </span>
                <TextValidator
                  fullWidth
                  multiline
                  rows={1}
                  type='text'
                  name='address'
                  value={address}
                  placeholder="Public Address"
                  className='text-field'
                  id='standard-full-width'
                  onChange={handleEditChange}
                />
              </div>

              <div className='col-12 group-form'>
                <span>Email: </span>
                <TextValidator
                  fullWidth
                  multiline
                  rows={1}
                  type='text'
                  name='email'
                  value={email}
                  placeholder="Enter Email"
                  className='text-field'
                  id='standard-full-width'
                  onChange={handleEditChange}
                />
              </div>

              <div className='col-12 group-form'>
                <span>First Name: </span>
                <TextValidator
                  fullWidth
                  multiline
                  rows={1}
                  type='text'
                  name='firstName'
                  value={firstName}
                  placeholder="Enter First Name"
                  className='text-field'
                  id='standard-full-width'
                  onChange={handleEditChange}
                />
              </div>

            </div>
            <div className="row">
              <div className="col-12 pt-4 pb-5 d-flex justify-content-center">
                <Button type="submit" variant="contained" className="add-btn">Submit</Button>
              </div>
            </div>
          </ValidatorForm>
        </ModalBody>

      </Modal>

      {/*------------------------DELETE MODAL ---------------------*/}

      <Modal isOpen={isDeleteAllowlistModal} toggle={() => toggleDeleteAllowistModal(false)} className="main-modal allowlist-modal" style={{ margin: "2.9rem auto" }}>
        <ModalHeader toggle={() => toggleDeleteAllowistModal(false)}>
          <div className="modal-logo">
            <img src={require('../../../src/static/images/logo.png')} alt="modal-logo" />
          </div>
          <div className="text-center"><h2 >DELETE ALLOWLISTED</h2></div>

        </ModalHeader>
        <ModalBody className="modal-body">
          <div className="text-center">
            <h4 >Are you sure you want to delete?</h4>
            <h4 >{deleteRecord['firstName']} ({deleteRecord['address'] || deleteRecord['email']}) </h4>
          </div>
          <div><hr /></div>
          <div className='d-flex justify-content-center mb-4'>
            <Button onClick={() => deleteRecord()} variant="contained" className="add-btn">Delete</Button>
          </div>

        </ModalBody>
      </Modal>


    </div>
  );
};

export default AllowlistSignups;
import EventBus from 'eventing-bus';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { setLoader, whitelistingAddresses } from '../../store/actions/Auth.js';
import { useDispatch, useSelector } from 'react-redux';
import { Web3Button, parseRevertReason } from '@thirdweb-dev/react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';


import './index.css';
import 'react-table-6/react-table.css';
import "react-datepicker/dist/react-datepicker.css";

const AddWhitelist = ({ crowdSaleAddress }) => {
  const dispatch = useDispatch();
  const { collectionId } = useParams();
  const [address, setAddress] = useState([]);

  const addAddressesToWhitelist = async (contract) => {
    if (address.length === 0) return EventBus.publish("error", "No address is given");
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(setLoader({ message: 'Whitelisting Address...', status: true }));

        const { receipt } = await contract.call("addAddressesToWhitelist", [address]);
        if (receipt) {
          dispatch(whitelistingAddresses({ collectionId, addresses: address }));
          resolve("Addresses whitelisted successfully");
        }
      } catch (e) {
        const reason = parseRevertReason(e);
        reject(reason);
        dispatch(setLoader({ message: 'Addresses not whitelisted successfully...', status: false }));
      };
    })
  };

  return (
    <>
      <div className='content'>
        <div className='collections-container'>
          <div className='collections-header'>
            <h1 className="collections-title">Add Address to Crowdsale</h1>
          </div>
        </div>
        <div className="edit-add-body offset-md-2 col-md-8 col-12">
          <ValidatorForm>
            <div className="row">
              <div className='edit-add-field col-12'>
                <TextValidator
                  fullWidth
                  type='textarea'
                  name='address'
                  value={address.join('\n')}
                  variant="outlined"
                  id='standard-full-width'
                  className='form-input-field'
                  label={<label>Addresses <span>*</span></label>}
                  onChange={(e) => {
                    const addresses = e.target.value.split('\n')
                    setAddress(addresses);
                  }}
                  multiline
                  rows={3}
                  helperText="Please separate addresses by giving line space"
                />
              </div>
              <div className='edit-add-buttons col-sm-12 mt-4'>
                <Web3Button contractAddress={crowdSaleAddress}
                  action={(contract) => addAddressesToWhitelist(contract)}
                  onError={(error) => EventBus.publish("error", error)}
                  onSuccess={(success) => EventBus.publish("success", success)}>
                  Submit
                </Web3Button>
              </div>
            </div>
          </ValidatorForm>
        </div>
      </div>
      
    </>
  )
};

export default AddWhitelist;

import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import PerfectScrollbar from "perfect-scrollbar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// core components
import routes from "../../routes.js";
import Loader from "../../components/Loader/index.js";
import Footer from "../../components/Footer/Footer.jsx";
import Sidebar from "../../components/Sidebar/Sidebar.jsx";
import AdminNavbar from "../../components/Navbars/AdminNavbar.jsx";

const Admin = () => {
  const [backgroundColor, setBackgroundColor] = useState("blue");
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const isLoader = useSelector(state => state.Auth.isLoader);

  const mainPanelRef = useRef(null);
  let ps;

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanelRef.current, { suppressScrollX: true });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    return () => {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  }, []);

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanelRef.current.scrollTop = 0;
  }, []);

  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setSidebarOpened(!sidebarOpened);
  };

  const getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (window.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <Router>
      <div className="wrapper">
        {isLoader && <Loader />}
        <Sidebar
          routes={routes}
          bgColor={backgroundColor}
          logo={{
            outterLink: "#",
            text: "CheebaPet",
          }}
          toggleSidebar={toggleSidebar}
        />
        <div className="main-panel" ref={mainPanelRef} data={backgroundColor}>
          <AdminNavbar
            brandText={getBrandText(window.location.pathname)}
            toggleSidebar={toggleSidebar}
            sidebarOpened={sidebarOpened}
          />
          <Switch>
            {routes.map((prop, key) => (
              <Route
                exact
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            ))}
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default Admin;

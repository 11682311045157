import moment from 'moment';
import { CSVLink } from 'react-csv';
import ReactTable from 'react-table-6';
import { Link } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import { Button } from '@material-ui/core';
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, Fragment } from 'react';
import { getAllRewards, updateShippedStatus, toggleShippedModal, setLoader } from '../../store/actions/Auth';

import './index.css';
import 'react-table-6/react-table.css';
import "react-datepicker/dist/react-datepicker.css";

const Rewards = () => {

  const dispatch = useDispatch();

  const [shippedData, setShippedData] = useState({});

  const { allRewards, isShippedModal } = useSelector(state => state.Auth);

  useEffect(() => { dispatch(getAllRewards()) }, [dispatch]);

  const handleSwitchShipped = () => {
    if (Object.keys(shippedData)) {
      dispatch(updateShippedStatus(shippedData));
      setLoader({ message: 'Updating Status...', status: true });
    }
  };

  const columns = [
    {
      id: 'publicAddress',
      Header: 'Public Address',
      accessor: 'publicAddress',
      Cell: ({ value }) => value ? <p className='w-100 text-center'>{value}</p> : '-'
    },
    {
      id: 'firstName',
      Header: 'First Name',
      accessor: 'firstName',
      Cell: ({ value }) => value ? <p className='w-100 text-center'>{value}</p> : '-'
    },
    {
      id: 'shippingEmail',
      Header: 'Shipping Email',
      accessor: 'shippingEmail',
      Cell: ({ value }) => value ? <p className='w-100 text-center'>{value}</p> : '-'
    },
    {
      id: 'country',
      Header: 'Country',
      accessor: 'country',
      Cell: ({ value }) => value ? <p className='w-100 text-center'>{value}</p> : '-'
    },
    {
      id: 'tokenIds',
      Header: 'Token Ids',
      accessor: 'tokenIds',
      Cell: ({ value }) => value ? <p className='w-100 text-center'>{value.join(',')}</p> : '-'
    },
    {
      id: 'quantities',
      Header: 'Quantity',
      accessor: 'tokenQuantity',
      Cell: ({ value }) => value ? <p className='w-100 text-center'>{value.join(',')}</p> : '-'
    },
    {
      id: 'type',
      Header: 'Type',
      accessor: 'isRewards',
      Cell: ({ value }) => <p className='w-100 text-center'>{value ? 'Rewards' : 'Purchased'}</p>
    },
    {
      id: 'date',
      Header: 'Date',
      accessor: 'createdAt',
      Cell: ({ value }) => moment(value).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      id: 'edit',
      Header: 'Actions',
      accessor: 'isShipped',
      Cell: ({ original }) => (
        <Switch
          checked={original['isShipped']}
          onChange={() => { setShippedData(original); dispatch(toggleShippedModal(true)) }}
        />
      ),
      filterable: false,
    },
  ];

  return (
    <div className='content'>
      <div className="main-container">
        <div className='collections-container'>
          <div className='collections-header'>
            <h1 className="collections-title">Rewards</h1>
            <br />
            <CSVLink data={allRewards} filename={"IRL-Planters.csv"} >
              <button className="add-btn">Export Data</button>
            </CSVLink>
          </div>
          <div>
            <Fragment>
              <div className='main-container-head mb-3'>
                <ReactTable
                  minRows={10}
                  className="table"
                  columns={columns}
                  filterable={true}
                  data={allRewards}
                  resolveData={data => data.map(row => row)}
                />
              </div>
            </Fragment>
          </div>
        </div>
      </div>

      {/* UPDATE SHIPPED STATUS  */}
      <Modal isOpen={isShippedModal} toggle={() => dispatch(toggleShippedModal(false))} className="main-modal allowlist-modal">
        <ModalHeader toggle={() => dispatch(toggleShippedModal(false))}>
          <div className="modal-logo">
            <img src={require('../../../src/static/images/logo.png')} alt="modal-logo" />
          </div>
          <div className="text-center"><h2 className="text-white">UPDATE SHIPPED STATUS</h2></div>
          <div className="text-white"><hr /></div>
        </ModalHeader>
        <ModalBody className="modal-body">
          <div className="row">
            <div className="col-12 pt-4 pb-5 d-flex justify-content-center">
              <h4 className='text-dark'>Are you sure you want to {shippedData['isShipped'] ? ' Disable ' : ' Enable '} Shipping Status</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-12 pt-4 pb-5 d-flex justify-content-around">
              <Button onClick={() => dispatch(toggleShippedModal(false))} className="delete-button">Cancel</Button>
              <Button onClick={() => handleSwitchShipped()} className="add-btn">Submit</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>

    </div>
  );
};

export default Rewards;

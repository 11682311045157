import EventBus from 'eventing-bus';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import MenuItem from '@mui/material/MenuItem';
import StepLabel from '@mui/material/StepLabel';
import InputLabel from '@mui/material/InputLabel';
import AddNewCrowdSaleStage from "../AddNewStage";
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import { useDispatch, useSelector } from 'react-redux';

import React, { useEffect, useState, Fragment } from 'react';
import { setLoader, deployCrowdsale } from '../../store/actions/Auth.js';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { getCrowdSaleAddress, crowdsaleAddressSuccess, getCollections } from '../../store/actions/Auth.js';

import './index.css';
import 'react-table-6/react-table.css';
import "react-datepicker/dist/react-datepicker.css";

const steps = ['Create Collection Address', 'Add Stages'];

const CreateNewCrowdsale = () => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [crowdsaleAddress, setCrowdsaleAddress] = useState("");
  const [collectionAddress, setCollectionAddress] = useState("");

  const { deployedCrowdSaleAddress, getCrowdSaleSuccess, collections } = useSelector((state) => state.Auth);

  const handleReset = () => { setActiveStep(0); };
  const isStepSkipped = (step) => skipped.has(step);
  const isStepOptional = (step) => { return false; };
  const handleBack = () => { setActiveStep((prevActiveStep) => prevActiveStep - 1); };

  useEffect(() => { dispatch(getCollections()); }, []);

  useEffect(() => {
    if (getCrowdSaleSuccess) {
      dispatch(setLoader({ message: 'Get deployed crowdsale address...', status: false }));
      setActiveStep(prevActiveStep => prevActiveStep + 1);
      setCrowdsaleAddress(deployedCrowdSaleAddress);
      crowdsaleAddressSuccess(false);
    }
  }, [getCrowdSaleSuccess]);

  const deployCrowdsaleContract = () => {
    dispatch(setLoader({ message: 'Deploy Crowdsale Contract...', status: true }));
    dispatch(deployCrowdsale({ collectionAddress }));
  }

  const handleNext = () => {
    if (activeStep === 0) {
      if (!collectionAddress) return EventBus.publish('error', `Please select collection address`);
      dispatch(getCrowdSaleAddress({ collectionAddress }));
      dispatch(setLoader({ message: 'Get deployed crowdsale address...', status: true }));
    } else {
      if (isStepSkipped(activeStep)) {
        const newSkipped = new Set(skipped.values());
        newSkipped.delete(activeStep);
        setSkipped(newSkipped);
      }

      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleCollectionAddressChange = (event) => {
    setCollectionAddress(event);
  };

  return (
    <div className='content'>
      <div className="main-container mint-new">
        <div className='collections-container'>
          <div className='collections-header collection-header'>
            <h1 className="collections-title">Deploy Crowdsale Contract</h1>
            {/* <div className='df'>
              
            </div> */}
          </div>
          <div>
            <Box sx={{ width: '100%' }}>
              <Stepper activeStep={activeStep} className='create-banner-step'>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  if (isStepOptional(index)) {
                    labelProps.optional = (
                      <Typography variant="caption">Optional</Typography>
                    );
                  }
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {activeStep === steps.length ? (
                <AddNewCrowdSaleStage crowdSaleAddress={crowdsaleAddress} />
              ) : (
                <Fragment>
                  <div className='row'>
                    <div className={activeStep <= 1 ? 'col-lg-12' : 'col-lg-12'}>
                      <Typography className='heading-step' sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
                    </div>
                  </div>
                  <form className="step-form">
                    {activeStep === 0 && (
                      <div className="edit-add-body col-12">
                        <ValidatorForm>
                          <div className="row">
                            <div className='edit-add-field mt-4'>
                              <label>Collection Address *</label>
                              <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">Collection Address</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={collectionAddress}
                                    label="TokenAddress"
                                    onChange={(e) => handleCollectionAddressChange(e.target.value)}
                                  >
                                    {collections && collections.map((collection) => {
                                      if (collection['deployedCollectionAddress']) {
                                        return (
                                          <MenuItem key={collection['collectionName']} value={collection['deployedCollectionAddress']}>
                                            {collection['collectionName']}
                                          </MenuItem>
                                        );
                                      }
                                      return null;
                                    })}
                                  </Select>
                                </FormControl>
                              </Box>



                            </div>
                            <div className='edit-add-buttons col-sm-12 mt-4'>
                              <button
                                type='button'
                                className="btn btn-primary"
                                onClick={() => deployCrowdsaleContract()}
                              >
                                Deploy Contract
                              </button>
                            </div>
                          </div>
                        </ValidatorForm>
                      </div>
                    )}
                    {activeStep === 1 && (
                      <AddNewCrowdSaleStage crowdSaleAddress={crowdsaleAddress} />
                    )}
                  </form>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                      color="inherit"
                      className='back-btn'
                      disabled={activeStep === 0}
                      onClick={() => handleBack()}
                      sx={{ mr: 1 }}
                    >
                      <img src={require('../../../src/static/images/back-arrow.png')} alt="modal-logo" />
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    {isStepOptional(activeStep) && (
                      <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                        Skip
                      </Button>
                    )}
                    <Button onClick={handleNext}>
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                  </Box>
                </Fragment>
              )}
            </Box>

          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewCrowdsale;

import EventBus from 'eventing-bus';
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from 'react-redux';
import { Web3Button, parseRevertReason } from '@thirdweb-dev/react';

import React, { useEffect, useState } from 'react';
import { setLoader } from '../../store/actions/Auth.js';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { saleStageAddedSuccess } from "../../store/actions/Auth.js";

import './index.css';
import 'react-table-6/react-table.css';
import "react-datepicker/dist/react-datepicker.css";

const AddNewCrowdSaleStage = ({ crowdSaleAddress, componentHeading }) => {
  const dispatch = useDispatch();
  const [stageName, setStageName] = useState('');
  const [stagePrice, setStagePrice] = useState('');
  const [mintingLimit, setMintingLimit] = useState('');
  const [closingTime, setClosingTime] = useState(new Date());
  const [openingTime, setOpeningTime] = useState(new Date());

  const handleClosingTimeChange = (date) => setClosingTime(date);
  const handleOpeningTimeChange = (date) => setOpeningTime(date);
  const handleStageNameChange = (e) => setStageName(e.target.value);
  const handleStagePriceChange = (e) => setStagePrice(e.target.value);
  const getUnixTimestamp = (date) => Math.floor(date.getTime() / 1000);
  const handleMintingLimitChange = (e) => setMintingLimit(e.target.value);

  const setCollectionData = async (contract) => {
    return new Promise(async (resolve, reject) => {
      try {
        const _openingTime = getUnixTimestamp(openingTime);
        const _closingTime = getUnixTimestamp(closingTime);

        dispatch(setLoader({ message: 'Add Stage in CrowdSale...', status: true }));

        const { receipt } = await contract.call("addCrowdsaleStage", [stageName, _openingTime, _closingTime, stagePrice, mintingLimit]);
        if (receipt) {
          if (componentHeading === "Add New SaleStage") dispatch(saleStageAddedSuccess(true))
          dispatch(setLoader({ message: 'Add Stage in CrowdSale...', status: false }));
          resolve("Stage Added successfully");
        }
      } catch (e) {
        const reason = parseRevertReason(e);
        reject(reason);
        dispatch(setLoader({ message: 'Stage not added successfully...', status: false }));
      };
    })
  };

  return (
    <>
        <div className='content'>
          {componentHeading &&
              <div className='collections-container'>
                <div className='collections-header'>
                  <h1 className="collections-title">{componentHeading}</h1>
                </div>
              </div>
          }
          <div className="edit-add-body offset-md-2 col-md-8 col-sm-12">
          <ValidatorForm>
            <div className="row">
              <div className='edit-add-field col-12'>
                <label>Stage Name *</label>
                <TextValidator
                  fullWidth
                  type='text'
                  name='stageName'
                  value={stageName}
                  variant="outlined"
                  id='standard-full-width'
                  className='form-input-field'
                  placeholder='Please enter the stage Name'
                  onChange={handleStageNameChange}
                />
              </div>
                <div className='col-lg-6 col-md-12'>
                  <div className='edit-add-field'>
                    <label>Opening Time</label>
                    <DatePicker
                      selected={openingTime}
                      onChange={handleOpeningTimeChange}
                      showTimeSelect
                      dateFormat="Pp"
                      className='form-input-field'
                    />
                  </div>
                </div>
                <div className='col-lg-6 col-md-12'>
                  <div className='edit-add-field'>
                    <label>Closing Time</label>
                    <DatePicker
                      selected={closingTime}
                      onChange={handleClosingTimeChange}
                      showTimeSelect
                      dateFormat="Pp"
                      className='form-input-field'
                    />
                  </div>
                </div>
                  <div className='col-lg-6 col-md-12'>
                  <div className='edit-add-field'>
                    <label>Stage Price</label>
                    <TextValidator
                      fullWidth
                      type='text'
                      name='stagePrice'
                      value={stagePrice}
                      variant="outlined"
                      id='standard-full-width'
                      className='form-input-field'
                      placeholder='Please enter the stage price'
                      onChange={handleStagePriceChange}
                    />
                  </div>
                </div>
              <div className='col-lg-6 col-md-12'>
                <div className='edit-add-field'>
                  <label>Minting Limit</label>
                  <TextValidator
                    fullWidth
                    type='text'
                    name='mintingLimit'
                    value={mintingLimit}
                    variant="outlined"
                    id='standard-full-width'
                    className='form-input-field'
                    placeholder='Please enter the Minting Limit'
                    onChange={handleMintingLimitChange}
                  />
                </div>
              </div>
              
              <div className='edit-add-buttons col-sm-12 mt-4'>
                <Web3Button contractAddress={crowdSaleAddress}
                  action={(contract) => setCollectionData(contract)}
                  onError={(error) => EventBus.publish("error", error)}
                  onSuccess={(success) => EventBus.publish("success", success)}>
                  Submit
                </Web3Button>
              </div>
            </div>
          </ValidatorForm>
        </div>
        </div>
      
    </>
  )
};

export default AddNewCrowdSaleStage;

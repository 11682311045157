import Rewards from "./app/Rewards/index.js"
import Airdrop from "./app/Airdrop/index.js";
import CrowdSale from "./app/Crowdsale/index.js";
import Collections from "./app/Collections/index.js";
import AddMetadata from "./app/AddMetadata/index.js";
import EditMetadata from "./app/EditMetadata/index.js";
import CrowdSaleStages from "./app/CrowdsaleStages/index.js";
import AllowlistSignups from "./app/AllowlistSignups/index.js";
import CreateNewCrowdsale from "./app/CreateNewCrowdsale/index.js";
import CrowdsaleWhitelist from "./app/CrowdsaleWhitelist/index.js";
import CreateNewCollection from "./app/CreateNewCollection/index.js";

var routes = [
  {
    layout: '/home',
    path: '/airdrop',
    component: Airdrop,
    name: 'Airdrop',
    icon: 'tim-icons icon-app',
    hidden: true,
  },
  {
    layout: "/home",
    path: "/allowList-signup",
    name: "AllowlistSignups",
    component: AllowlistSignups,
    icon: "tim-icons icon-badge",
  },
  {
    layout: "/home",
    path: "/collection",
    name: "Collections",
    component: Collections,
    icon: "tim-icons icon-bank",
    hidden: true,
  },
  {
    layout: "/home",
    path: "/crowdsale",
    name: "CrowdSale",
    component: CrowdSale,
    icon: "tim-icons icon-chart-pie-36",
    hidden: true,
  },
  {
    layout: "/home",
    path: "/rewards",
    name: "Rewards",
    component: Rewards,
    icon: "tim-icons icon-chart-pie-36",
  },
  {
    layout: "/home",
    path: "/createnewcollection",
    name: "CreateNewCollection",
    component: CreateNewCollection,
    icon: "tim-icons icon-badge",
    hidden: true
  },
  {
    layout: "/home",
    path: "/CreateNewCrowdsale",
    name: "CreateNewCrowdsale",
    component: CreateNewCrowdsale,
    icon: "tim-icons icon-coins",
    hidden: true
  },
  {
    layout: "/home",
    path: "/createnewcollection/add-new-metadata/:collectionName/:metadataId",
    name: "AddMetadata",
    component: AddMetadata,
    icon: "tim-icons icon-coins",
    hidden: true
  },
  {
    layout: "/home/collection",
    path: "/editcollection/:collectionName",
    name: "EditMetadata",
    component: EditMetadata,
    icon: "tim-icons icon-coins",
    hidden: true
  },
  {
    layout: "/home/crowdsale",
    path: "/crowdsaleStage/:collectionId",
    name: "CrowdSaleStages",
    component: CrowdSaleStages,
    icon: "tim-icons icon-coins",
    hidden: true
  },
  {
    layout: "/home/crowdsale",
    path: "/whitelist/:collectionId",
    name: "CrowdsaleWhitelist",
    component: CrowdsaleWhitelist,
    icon: "tim-icons icon-coins",
    hidden: true
  }
];

export default routes;